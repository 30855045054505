import React, { useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import {
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

function App() {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);
  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
      <Link to="/">
          <ArrowBackIcon
            color="primary"
            style={{
              color: "#0266ba",
              marginTop: "15px",
              marginBottom: "-24px",
            }}
            fontSize="large"
          />
        </Link>
        <div className="center">
          <img src={logo} alt="logo" />
          <h1 style={{ fontSize: "16px" }}>SYARAT DAN KETENTUAN</h1>
        </div>
        <div className="spacer-15"></div>
        <div>
          <div className="wrap">
            <h2>Layanan Gratis</h2>
            <p>
              Layanan SooSoon.id adalah layanan gratis dengan batasan pada
              beberapa fitur yang hanya didapatkan dengan meningkatkan ke jenis
              layanan berbayar
              <br />
              <br />
              Layanan gratis kami terdiri dari tiga tombol link, satu bagian
              promosi dan tiga kategori
              <br />
              <br />
              Layanan gratis ini akan Anda terima cukup dengan melakukan
              registrasi awal
            </p>
            <h2>Registrasi dan Otentikasi</h2>
            <p>
              Registrasi dilakukan dengan memasukkan email dan data pendukung
              lain. Data ini akan disimpan dalam server kami dan tidak akan kami
              bagikan pada pihak ketiga (PRIVACY POLICY)
              <br />
              <br />
              Dengan melakukan registrasi, pelanggan memberikan ijin kepada
              sooson.id untuk mengirimkan materi promosi dan update berkala
              melalui email pelanggan
              <br />
              <br />
              Proses Otentikasi dilakukan oleh pihak Instagram dengan Anda tanpa
              melewati server Soosoon.id Data Instagram Anda tidak disimpan dan
              tidak melewati server Soosoon.id
            </p>
            <h2>Layanan Langganan Berbayar (pro)</h2>
            <p>
              Layanan Langganan berbayar Soosoon.id menambahkan beberapa fitur
              baru dari layanan gratis kami.
              <br />
              <br />
              Fitur tersebut termasuk di dalamnya: penambahan jumlah tombol
              link, dan kategori.
              <br />
              <br />
              Dengan layanan berbayar, pelanggan juga mendapatkan satu template
              secara cuma-cuma setiap kali Soosoon.id mengeluarkan paket
              template.
              <br />
              <br />
              Fitur layanan berbayar akan terus kami tingkatkan seiring dengan
              berjalannya waktu.
              <br />
              <br />
              Layanan langganan Soosoon dapat dibayar perbulan atau pertahun.
              <br />
              <br />
              Pembayaran dilakukan melalui pihak ketiga (payment gateway yang
              ditunjuk). Soosoon.id tidak pernah menyimpan informasi pembayaran
              pelanggan.
            </p>
            <h2>Layanan Berbayar Satuan</h2>
            <p>
              Layanan berbayar satuan Soosoon.id digunakan untuk pembayaran tema
              desain pelanggan
              <br />
              <br />
              Tema desain akan ditambahkan secara berkala
              <br />
              <br />
              Pembayaran dilakukan melalui pihak ketiga (payment gateway yang
              ditunjuk). Soosoon.id tidak pernah menyimpan informasi pembayaran
              pelanggan.
              <br />
              <br />
              Soosoon.id sebagai penyedia layanan media untuk menampilkan konten
              dalam akun Instagram pelanggan. <br />
              <br />
              Soosoon.id melarang segala bentuk konten media yang mengandung
              unsur SARA, pornografi dan hal-hal lain yang melanggar aturan
              perundangan Republik Indonesia kepada setiap pelanggan.
              <br />
              <br />
              Apabila ada penyalahgunaan terhadap layanan kami, Soosoon.id
              berhak secara sepihak menonaktifkan akun pelanggan tanpa
              peringatan sebelumnya.
              <br />
              <br />
              Soosoon.id tidak bertanggungjawab atas penyalahgunaan layanan oleh
              pelanggan dan semua konten dan media yang ditampilkan adalah milik
              pelanggan, bukan milik Soosoon.id
              <br />
              <br />
              Logo, desain tema dan template Soosoon.id adalah hak cipta
              Soosoon.id dan tidak dapat dipergunakan tanpa seijin dari
              Soosoon.id
              <br />
              <br />
              Syarat dan ketentuan ini bisa berubah kapan saja dan perubahan ini
              akan kami informasikan kepada setiap pelanggan sebelumnya.
            </p>
          </div>
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="center">&copy; Copyright 2020 Grab Essentials</p>
        </div>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
