import React, { useState, useEffect } from "react";
import "../App.css";
import carouselIcon from "../img/carousel.png";
import videoIcon from "../img/video.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { Redirect, useParams } from "react-router-dom";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import InstagramEmbed from "react-instagram-embed";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from "@material-ui/icons/Edit";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import StorefrontIcon from "@material-ui/icons/Storefront";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

const theme1 = createMuiTheme({
  palette: {
    primary: { main: "#ffffff" },
    secondary: { main: "#000000" },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: "-30px",
    textAlign: "center",
    flex: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function App(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [embed, setEmbed] = React.useState("");
  const [profile, setProfile] = useState([]);
  const [medias, setMedias] = useState([]);
  const [links, setLinks] = useState([]);
  const [specificLinks, setSpecificLinks] = useState([]);
  const [gridLink, setGridLink] = useState(4);
  const [myTheme, setMyTheme] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [activeAccordion, setActiveAccordino] = useState([]);
  const [loadingLink, setLoadingLink] = useState(0);
  const [loadingMedia, setLoadingMedia] = useState(0);
  const token = localStorage.getItem("api_token");
  const [formLink, setFormLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputUrl, setInputurl] = useState("");
  const [post_id, set_post_id] = useState("");
  const [statusSpecificLink, setStatusSpecificLink] = useState("");
  const [url_preview, set_url_preview] = useState("");

  const handleClickOpen = (embed, specific_link) => {
    setEmbed(embed);    
    if (specific_link != "" && !specific_link.match(/^[a-zA-Z]+:\/\//)) {
      specific_link = "http://" + specific_link;
    }
    set_url_preview(specific_link);
    setOpen(true);
  };

  const openFormLink = (post_id, statusSpecificLink, specific_url = "") => {
    setFormLink(true);
    setInputurl(specific_url);
    set_post_id(post_id);
    setStatusSpecificLink(statusSpecificLink);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUrlLink = (e) => {
    setInputurl(e.target.value);
  };

  const handleSubmitLink = () => {
    setFormLink(false);
    setLoading(true);
    axios
      .post(
        "https://soosoon.id/backend/public/api/specific_links",
        {
          post_id: post_id,
          url: inputUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setInputurl("");
        set_post_id("");
        getUsers();
      });
  };

  useEffect(() => {
    getUsers();
    getUsername();
    getLinks();
    getSpecificLinks();
    getTheme();
    // getUserProfile();
  }, []);

  const getUsers = () => {
    axios
      .get(`https://soosoon.id/backend/public/api/users/${props.username}`)
      .then((res) => {
        if (res.data.status === true) {
          setMedias(res.data.medias);
          let arr = [];
          res.data.medias.map((value, index) => {
            if (index == 0) {
              arr[index] = 1;
            } else if (index == 1) {
              arr[index] = 1;
            } else {
              arr[index] = 0;
            }
          });
          setActiveAccordino(arr);
          setLoadingMedia(1);
          setLoading(false);
        }
      });
  };

  const getUsername = () => {
    axios
      .get(
        `https://soosoon.id/backend/public/api/users/username/${props.username}`
      )
      .then((res) => {
        if (res.data.status === true) {
          axios
            .get(
              `https://www.instagram.com/${res.data.profile.username}/?__a=1`
            )
            .then((res) => {
              setProfile(res.data);
            });
        }
      });
  };

  const getLinks = () => {
    axios
      .get(
        `https://soosoon.id/backend/public/api/links/profile/${props.username}`
      )
      .then((res) => {
        if (res.data.status === true) {
          setLinks(res.data.data);
          if (res.data.grid == 1) {
            setGridLink(12);
          } else if (res.data.grid == 2) {
            setGridLink(6);
          } else if (res.data.grid == 3) {
            setGridLink(4);
          }
          setLoadingLink(1);
        }
      });
  };

  const getSpecificLinks = () => {
    axios
      .get("https://soosoon.id/backend/public/api/specific_links", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setSpecificLinks(res.data.data);
        }
      });
  };

  const getTheme = () => {
    axios
      .get(`https://soosoon.id/backend/public/api/themes/${props.idPreview}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setMyTheme(res.data.data);
        }
      });
  };

  const changeActiveAccordion = (index) => {
    let newacc = [...activeAccordion];
    if (newacc[index] == 0) {
      newacc[index] = 1;
    } else {
      newacc[index] = 0;
    }
    setActiveAccordino(newacc);
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div
          className="wrapperPreview"
          style={{
            margin: "0 auto",
            backgroundImage: `url(${myTheme.background_img})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "top",
            width: "440px",
            maxWidth: "100%",
          }}
        >
          <Container maxWidth="xs" className="center">
            <div className="spacer-30"></div>
            {profile.length === 0 ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={95}
                  height={95}
                  style={{ margin: "0 auto" }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={95}
                  style={{ margin: "0 auto" }}
                />
              </>
            ) : (
              <>
                {/* <img
                  style={{ borderRadius: "100%", width: "95px" }}
                  src={profile.graphql.user.profile_pic_url_hd}
                  alt={`foto profil' ${profile.graphql.user.full_name}`}
                />
                <p
                  className="username"
                  style={{ color: myTheme.primary_color }}
                >
                  @{profile.graphql.user.username}
                </p> */}
              </>
            )}
            <div className="spacer-15"></div>
            <Grid container spacing={2} className="center">
              {loadingLink === 0 ? (
                <>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={50}
                      style={{ margin: "0 auto", borderRadius: "15px" }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={50}
                      style={{ margin: "0 auto", borderRadius: "15px" }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={50}
                      style={{ margin: "0 auto", borderRadius: "15px" }}
                    />
                  </Grid>
                </>
              ) : (
                links.map((value, index) => {
                  if (!value.url.match(/^[a-zA-Z]+:\/\//)) {
                    value.url = "http://" + value.url;
                  }
                  return (
                    <Grid
                      key={index}
                      item
                      xs={gridLink}
                      style={{ position: "relative" }}
                    >
                      <a
                        target="_blank"
                        href={value.url}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="button btn-sm"
                          style={{
                            color: myTheme.button_text_color,
                            backgroundColor: myTheme.button_color,
                            boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                            border: `2px solid ${myTheme.border_color}`,
                          }}
                          fullWidth
                        >
                          {value.name}
                        </Button>
                      </a>
                    </Grid>
                  );
                })
              )}
            </Grid>
            <div className="spacer-30"></div>
            <div
              className="garis"
              style={{ color: myTheme.secondary_color }}
            ></div>
            <div className="spacer-15"></div>
          </Container>
          <Container maxWidth="xs">
            {loadingMedia === 0 ? (
              <>
                <Skeleton animation="wave" variant="text" width={95} />
                <Grid container spacing={2} className="center">
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                </Grid>
                <Skeleton animation="wave" variant="text" width={95} />
                <Grid container spacing={2} className="center">
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Skeleton
                      animation="wave"
                      width={95}
                      height={150}
                      style={{
                        margin: "0 auto",
                        borderRadius: "15px",
                        marginTop: "-60px",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
            {medias.map((value, index) => {
              if (value.type == 0) {
                return (
                  <React.Fragment key={index}>
                    <p
                      className="info infohome"
                      style={{ color: myTheme.primary_color }}
                    >
                      {value.name}
                    </p>
                    <div className="forcefull">
                      <Carousel
                        clickToChange
                        slidesPerPage={2}
                        centered
                        keepDirectionWhenDragging
                      >
                        {value.data.map((item, i) => {
                          !item.media_url && getUsers();
                          return (
                            <>
                              {item.media_type == "CAROUSEL_ALBUM" && (
                                <>
                                  <img
                                    key={i}
                                    src={item.media_url}
                                    className="carouselimg carouselimghome"
                                    onClick={() =>
                                      handleClickOpen(
                                        item.permalink,
                                        item.specific_url
                                          ? item.specific_url
                                          : ""
                                      )
                                    }
                                  />
                                  <img
                                    src={carouselIcon}
                                    style={{
                                      width: "25px",
                                      position: "absolute",
                                      right: 20,
                                      top: 10,
                                    }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="button btn-sm"
                                    style={{
                                      color: myTheme.button_text_color,
                                      backgroundColor: myTheme.button_color,
                                      boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                                      border: `2px solid ${myTheme.border_color}`,
                                      width: "90%",
                                      marginBottom: "30px",
                                    }}
                                    onClick={
                                      item.specific_url
                                        ? () =>
                                            openFormLink(
                                              item.id,
                                              1,
                                              item.specific_url
                                            )
                                        : () => openFormLink(item.id, 0)
                                    }
                                  >
                                    {item.specific_url
                                      ? "UBAH LINK"
                                      : "TAMBAHKAN LINK"}
                                  </Button>
                                </>
                              )}
                              {item.media_type == "VIDEO" && (
                                <>
                                  <img
                                    key={i}
                                    src={item.thumbnail_url}
                                    className="carouselimg carouselimghome"
                                    onClick={() =>
                                      handleClickOpen(
                                        item.permalink,
                                        item.specific_url
                                          ? item.specific_url
                                          : ""
                                      )
                                    }
                                  />
                                  <img
                                    src={videoIcon}
                                    style={{
                                      width: "28px",
                                      position: "absolute",
                                      right: 20,
                                      top: 8,
                                    }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="button btn-sm"
                                    style={{
                                      color: myTheme.button_text_color,
                                      backgroundColor: myTheme.button_color,
                                      boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                                      border: `2px solid ${myTheme.border_color}`,
                                      width: "90%",
                                    }}
                                    onClick={
                                      item.specific_url
                                        ? () =>
                                            openFormLink(
                                              item.id,
                                              1,
                                              item.specific_url
                                            )
                                        : () => openFormLink(item.id, 0)
                                    }
                                  >
                                    {item.specific_url
                                      ? "UBAH LINK"
                                      : "TAMBAHKAN LINK"}
                                  </Button>
                                </>
                              )}
                              {item.media_type == "IMAGE" && (
                                <>
                                  <img
                                    key={i}
                                    src={item.media_url}
                                    className="carouselimg carouselimghome"
                                    onClick={() =>
                                      handleClickOpen(
                                        item.permalink,
                                        item.specific_url
                                          ? item.specific_url
                                          : ""
                                      )
                                    }
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="button btn-sm"
                                    style={{
                                      color: myTheme.button_text_color,
                                      backgroundColor: myTheme.button_color,
                                      boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                                      border: `2px solid ${myTheme.border_color}`,
                                      width: "90%",
                                    }}
                                    onClick={
                                      item.specific_url
                                        ? () =>
                                            openFormLink(
                                              item.id,
                                              1,
                                              item.specific_url
                                            )
                                        : () => openFormLink(item.id, 0)
                                    }
                                  >
                                    {item.specific_url
                                      ? "UBAH LINK"
                                      : "TAMBAHKAN LINK"}
                                  </Button>
                                </>
                              )}
                            </>
                          );
                        })}
                      </Carousel>
                      <div
                        className="garis"
                        style={{ color: myTheme.secondary_color }}
                      ></div>
                      <div className="spacer-15"></div>
                    </div>
                  </React.Fragment>
                );
              }
            })}
            {medias.map((value, index) => {
              if (value.type != 0) {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="accordiontitle"
                      onClick={() => changeActiveAccordion(index)}
                    >
                      <p
                        className="info infohome"
                        style={{ color: myTheme.primary_color }}
                      >
                        {value.name}
                      </p>
                      <span>
                        <ArrowDropDownCircleIcon
                          color="primary"
                          style={{ color: myTheme.primary_color }}
                          className={activeAccordion[index] == 1 ? "" : "tutup"}
                        />
                      </span>
                    </div>
                    <div
                      className={
                        activeAccordion[index] == 1 ? "" : "accordion-non"
                      }
                    >
                      <Grid container spacing={2} className="center">
                        {value.data.map((item, i) => {
                          const caption = item.caption.split("\n")[0];
                          const first = caption.substring(0, 45);
                          !item.media_url && getUsers();
                          return (
                            <Grid
                              item
                              xs={4}
                              key={i}
                              style={{ position: "relative" }}
                            >
                              <div style={{ postion: "relative" }}>
                                {item.media_type == "CAROUSEL_ALBUM" && (
                                  <>
                                    <img
                                      src={item.media_url}
                                      alt={first}
                                      className="carouselimg contentimg"
                                      onClick={() =>
                                        handleClickOpen(
                                          item.permalink,
                                          item.specific_url
                                            ? item.specific_url
                                            : ""
                                        )
                                      }
                                    />
                                    <img
                                      src={carouselIcon}
                                      style={{
                                        width: "19px",
                                        position: "absolute",
                                        right: 12,
                                        top: 12,
                                      }}
                                    />
                                  </>
                                )}
                                {item.media_type == "VIDEO" && (
                                  <>
                                    <img
                                      src={item.thumbnail_url}
                                      alt={first}
                                      className="carouselimg contentimg"
                                      onClick={() =>
                                        handleClickOpen(
                                          item.permalink,
                                          item.specific_url
                                            ? item.specific_url
                                            : ""
                                        )
                                      }
                                    />
                                    <img
                                      src={videoIcon}
                                      style={{
                                        width: "20px",
                                        position: "absolute",
                                        right: 12,
                                        top: 10,
                                      }}
                                    />
                                  </>
                                )}
                                {item.media_type == "IMAGE" && (
                                  <img
                                    src={item.media_url}
                                    alt={first}
                                    className="carouselimg contentimg"
                                    onClick={() =>
                                      handleClickOpen(
                                        item.permalink,
                                        item.specific_url
                                          ? item.specific_url
                                          : ""
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <p
                                className="title"
                                style={{ color: myTheme.secondary_color }}
                              >
                                {first}
                              </p>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="button btn-sm"
                                style={{
                                  color: myTheme.button_text_color,
                                  backgroundColor: myTheme.button_color,
                                  boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                                  border: `2px solid ${myTheme.border_color}`,
                                  width: "90%",
                                }}
                                onClick={
                                  item.specific_url
                                    ? () =>
                                        openFormLink(
                                          item.id,
                                          1,
                                          item.specific_url
                                        )
                                    : () => openFormLink(item.id, 0)
                                }
                              >
                                {item.specific_url ? (
                                  <>
                                    <EditIcon fontSize="small" />
                                    &nbsp;LINK
                                  </>
                                ) : (
                                  <>
                                    <AddCircleIcon fontSize="small" />
                                    &nbsp;LINK
                                  </>
                                )}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <div className="spacer-15"></div>
                    </div>
                    {/* <Grid container spacing={2} className="center">
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="button btn-sm"
                      fullWidth
                    >
                      Load More
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <div className="spacer-15"></div> */}
                  </React.Fragment>
                );
              }
            })}
            <div className="center">
              <div className="spacer-30"></div>
              <div
                className="garis"
                style={{ color: myTheme.secondary_color }}
              ></div>
              <p
                className="copyright"
                style={{ color: myTheme.secondary_color }}
              >
                &copy; Copyright 2020 Grab Essentials
              </p>
            </div>
          </Container>
        </div>
        <Dialog open={formLink} aria-labelledby="form-dialog-title" fullWidth>
          <DialogTitle id="form-dialog-title">Link Post</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {statusSpecificLink == 0
                ? "Tambahakan link post kamu disini!"
                : "Ubah link post kamu disini!"}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="URL Post"
              type="text"
              fullWidth
              color="primary"
              onChange={(e) => handleUrlLink(e)}
              value={inputUrl}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFormLink(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitLink} color="primary">
              Simpan
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </MuiThemeProvider>
      <MuiThemeProvider theme={theme1}>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          className="homedialog"
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
              <div style={{ textAlign: "right", flexGrow: 1 }}>
                <a
                  style={{ color: "#000000", textDecoration: "none" }}
                  target="_blank"
                  href={embed}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="button btn-sm"
                    style={{
                      color: myTheme.button_text_color,
                      backgroundColor: myTheme.button_color,
                      boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                      border: `2px solid ${myTheme.border_color}`,
                    }}
                  >
                    HAPUS HASHTAG
                  </Button>                  
                </a>
              </div>
            </Toolbar>
          </AppBar>
          {url_preview != "" ? (
            <>
              <div className="shop">
                <a
                  href={url_preview}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                  >
                    {/* <StorefrontIcon className={classes.extendedIcon} /> */}
                    BELI SEKARANG
                  </Fab>
                </a>
              </div>              
            </>
          ) : null}
          <div
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  marginTop: "15px",
                  paddingRight: "8px",
                  marginBottom: "60px",
                }}
              >
                <InstagramEmbed
                  url={embed}
                  maxWidth={320}
                  hideCaption={false}
                  containerTagName="div"
                />
              </div>
        </Dialog>
      </MuiThemeProvider>
    </>
  );
}

export default App;
