import React, { useState, useEffect } from "react";
import "../App.css";
import carouselIcon from "../img/carousel.png";
import videoIcon from "../img/video.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { Redirect, useParams } from "react-router-dom";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import InstagramEmbed from "react-instagram-embed";
import Fab from "@material-ui/core/Fab";
import StorefrontIcon from "@material-ui/icons/Storefront";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: "-30px",
    textAlign: "center",
    flex: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [embed, setEmbed] = React.useState("");
  const { username } = useParams();
  const [profile, setProfile] = useState([]);
  const [loadingLink, setLoadingLink] = useState(0);
  const [loadingMedia, setLoadingMedia] = useState(0);
  const [medias, setMedias] = useState([]);
  const [links, setLinks] = useState([]);
  const [gridLink, setGridLink] = useState(4);
  const [myTheme, setMyTheme] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [activeAccordion, setActiveAccordino] = useState([]);
  const [url_preview, set_url_preview] = useState("");

  const handleClickOpen = (embed, specific_link) => {
    setEmbed(embed);
    if (specific_link != "" && !specific_link.match(/^[a-zA-Z]+:\/\//)) {
      specific_link = "http://" + specific_link;
    }
    set_url_preview(specific_link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUsers();
    getUsername();
    getLinks();
    getTheme();
    // getUserProfile();
    document.body.style.setProperty(
      "background-attachment",
      "fixed",
      "important"
    );
  }, []);

  useEffect(() => {
    if (myTheme.length !== 0) {
      document.body.style.setProperty(
        "background-image",
        `url(${myTheme.background_img})`,
        "important"
      );
    }
  });

  const getUsers = () => {
    axios
      .get(`https://soosoon.id/backend/public/api/users/${username}`)
      .then((res) => {
        if (res.data.status === true) {
          setMedias(res.data.medias);
          let arr = [];
          res.data.medias.map((value, index) => {
            if (index == 0) {
              arr[index] = 1;
            } else if (index == 1) {
              arr[index] = 1;
            } else {
              arr[index] = 0;
            }
          });
          setActiveAccordino(arr);

          setLoadingMedia(1);
        }
      })
      .catch((e) => {
        setRedirect(true);
      });
  };

  const getUsername = () => {
    axios
      .get(`https://soosoon.id/backend/public/api/users/username/${username}`)
      .then((res) => {
        document.title = `@${res.data.profile.username} | Soosoon`;
        if (res.data.status === true) {
          axios
            .get(
              `https://www.instagram.com/${res.data.profile.username}/?__a=1`
            )
            .then((res) => {
              setProfile(res.data);
            });
        }
      });
  };

  const getLinks = () => {
    axios
      .get(`https://soosoon.id/backend/public/api/links/profile/${username}`)
      .then((res) => {
        if (res.data.status === true) {
          setLinks(res.data.data);
          if (res.data.grid == 1) {
            setGridLink(12);
          } else if (res.data.grid == 2) {
            setGridLink(6);
          } else if (res.data.grid == 3) {
            setGridLink(4);
          }
          setLoadingLink(1);
        }
      });
  };

  const getTheme = () => {
    axios
      .get(
        `https://soosoon.id/backend/public/api/themes/profile/${username}`,
        {}
      )
      .then((res) => {
        if (res.data.status === true) {
          setMyTheme(res.data.data);
        }
      });
  };

  const changeActiveAccordion = (index) => {
    let newacc = [...activeAccordion];
    if (newacc[index] == 0) {
      newacc[index] = 1;
    } else {
      newacc[index] = 0;
    }
    setActiveAccordino(newacc);
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs" className="center">
        <div className="spacer-30"></div>
        {profile.length === 0 ? (
          <>
            <Skeleton
              animation="wave"
              variant="circle"
              width={95}
              height={95}
              style={{ margin: "0 auto" }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width={95}
              style={{ margin: "0 auto" }}
            />
          </>
        ) : (
          <>
            {/* <img
              style={{ borderRadius: "100%", width: "95px" }}
              src={profile.graphql.user.profile_pic_url_hd}
              alt={`foto profil' ${profile.graphql.user.full_name}`}
            />
            <p className="username" style={{ color: myTheme.primary_color }}>
              @{profile.graphql.user.username}
            </p> */}
          </>
        )}
        <div className="spacer-15"></div>
        <Grid container spacing={2} className="center">
          {loadingLink === 0 ? (
            <>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={50}
                  style={{ margin: "0 auto", borderRadius: "15px" }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={50}
                  style={{ margin: "0 auto", borderRadius: "15px" }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={50}
                  style={{ margin: "0 auto", borderRadius: "15px" }}
                />
              </Grid>
            </>
          ) : (
            links.map((value, index) => {
              if (!value.url.match(/^[a-zA-Z]+:\/\//)) {
                value.url = "http://" + value.url;
              }
              return (
                <Grid
                  key={index}
                  item
                  xs={gridLink}
                  style={{ position: "relative" }}
                >
                  <a
                    target="_blank"
                    href={value.url}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="button btn-sm"
                      style={{
                        color: myTheme.button_text_color,
                        backgroundColor: myTheme.button_color,
                        boxShadow: `3px 3px 4px ${myTheme.box_shadow_color}`,
                        border: `2px solid ${myTheme.border_color}`,
                      }}
                      fullWidth
                    >
                      {value.name}
                    </Button>
                  </a>
                </Grid>
              );
            })
          )}
        </Grid>
        <div className="spacer-30"></div>
        <div className="garis" style={{ color: myTheme.secondary_color }}></div>
        <div className="spacer-15"></div>
      </Container>
      <Container maxWidth="xs">
        {loadingMedia === 0 ? (
          <>
            <Skeleton animation="wave" variant="text" width={95} />
            <Grid container spacing={2} className="center">
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
            </Grid>
            <Skeleton animation="wave" variant="text" width={95} />
            <Grid container spacing={2} className="center">
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-30px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Skeleton
                  animation="wave"
                  width={95}
                  height={150}
                  style={{
                    margin: "0 auto",
                    borderRadius: "15px",
                    marginTop: "-60px",
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
        {medias.map((value, index) => {
          if (value.type == 0) {
            return (
              <React.Fragment key={index}>
                <p
                  className="info infohome"
                  style={{ color: myTheme.primary_color }}
                >
                  {value.name}
                </p>
                <div className="forcefull">
                  <Carousel
                    clickToChange
                    slidesPerPage={2}
                    centered
                    keepDirectionWhenDragging
                  >
                    {value.data.map((item, i) => {
                      const caption = item.caption.split("\n")[0];
                      const first = caption.substring(0, 45);
                      !item.media_url && getUsers();
                      return (
                        <>
                          {item.media_type == "CAROUSEL_ALBUM" && (
                            <>
                              <img
                                key={i}
                                src={item.media_url}
                                alt={first}
                                className="carouselimg carouselimghome"
                                onClick={() =>
                                  handleClickOpen(
                                    item.permalink,
                                    item.specific_url ? item.specific_url : ""
                                  )
                                }
                              />
                              <img
                                src={carouselIcon}
                                style={{
                                  width: "25px",
                                  position: "absolute",
                                  right: 20,
                                  top: 10,
                                }}
                              />
                            </>
                          )}
                          {item.media_type == "VIDEO" && (
                            <>
                              <img
                                key={i}
                                src={item.thumbnail_url}
                                alt={first}
                                className="carouselimg carouselimghome"
                                onClick={() =>
                                  handleClickOpen(
                                    item.permalink,
                                    item.specific_url ? item.specific_url : ""
                                  )
                                }
                              />
                              <img
                                src={videoIcon}
                                style={{
                                  width: "28px",
                                  position: "absolute",
                                  right: 20,
                                  top: 8,
                                }}
                              />
                            </>
                          )}
                          {item.media_type == "IMAGE" && (
                            <img
                              key={i}
                              src={item.media_url}
                              alt={first}
                              className="carouselimg carouselimghome"
                              onClick={() =>
                                handleClickOpen(
                                  item.permalink,
                                  item.specific_url ? item.specific_url : ""
                                )
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </Carousel>
                  <div
                    className="garis"
                    style={{ color: myTheme.secondary_color }}
                  ></div>
                  <div className="spacer-15"></div>
                </div>
              </React.Fragment>
            );
          }
        })}
        {medias.map((value, index) => {
          if (value.type != 0) {
            return (
              <React.Fragment key={index}>
                <div
                  className="accordiontitle"
                  onClick={() => changeActiveAccordion(index)}
                >
                  <p
                    className="info infohome"
                    style={{ color: myTheme.primary_color }}
                  >
                    {value.name}
                  </p>
                  <span>
                    <ArrowDropDownCircleIcon
                      color="primary"
                      style={{ color: myTheme.primary_color }}
                      className={activeAccordion[index] == 1 ? "" : "tutup"}
                    />
                  </span>
                </div>
                <div
                  className={activeAccordion[index] == 1 ? "" : "accordion-non"}
                >
                  <Grid container spacing={2} className="center">
                    {value.data.map((item, i) => {
                      const caption = item.caption.split("\n")[0];
                      const first = caption.substring(0, 45);
                      !item.media_url && getUsers();
                      return (
                        <Grid
                          item
                          xs={4}
                          key={i}
                          style={{ position: "relative" }}
                        >
                          <div style={{ postion: "relative" }}>
                            {item.media_type == "CAROUSEL_ALBUM" && (
                              <>
                                <img
                                  src={item.media_url}
                                  alt={first}
                                  className="carouselimg contentimg"
                                  onClick={() =>
                                    handleClickOpen(
                                      item.permalink,
                                      item.specific_url ? item.specific_url : ""
                                    )
                                  }
                                />
                                <img
                                  src={carouselIcon}
                                  style={{
                                    width: "19px",
                                    position: "absolute",
                                    right: 12,
                                    top: 12,
                                  }}
                                />
                              </>
                            )}
                            {item.media_type == "VIDEO" && (
                              <>
                                <img
                                  src={item.thumbnail_url}
                                  alt={first}
                                  className="carouselimg contentimg"
                                  onClick={() =>
                                    handleClickOpen(
                                      item.permalink,
                                      item.specific_url ? item.specific_url : ""
                                    )
                                  }
                                />
                                <img
                                  src={videoIcon}
                                  style={{
                                    width: "20px",
                                    position: "absolute",
                                    right: 12,
                                    top: 10,
                                  }}
                                />
                              </>
                            )}
                            {item.media_type == "IMAGE" && (
                              <>
                                <img
                                  src={item.media_url}
                                  alt={first}
                                  className="carouselimg contentimg"
                                  onClick={() =>
                                    handleClickOpen(
                                      item.permalink,
                                      item.specific_url ? item.specific_url : ""
                                    )
                                  }
                                />
                              </>
                            )}
                          </div>
                          <p
                            className="title"
                            style={{ color: myTheme.secondary_color }}
                          >
                            {first}
                          </p>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div className="spacer-15"></div>
                </div>
                {/* <Grid container spacing={2} className="center">
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="button btn-sm"
                      fullWidth
                    >
                      Load More
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <div className="spacer-15"></div> */}
              </React.Fragment>
            );
          }
        })}
        <div className="center">
          <div className="spacer-30"></div>
          <div
            className="garis"
            style={{ color: myTheme.secondary_color }}
          ></div>
          <p className="copyright" style={{ color: myTheme.secondary_color }}>
            Powered by{" "}
            <a
              style={{ color: myTheme.secondary_color }}
              href="/"
              target="_blank"
            >
              SOOSOON
            </a>
          </p>
        </div>
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="homedialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {url_preview != "" ? (
          <>
            <div className="shop">
              <a
                href={url_preview}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  className={classes.margin}
                >
                  {/* <StorefrontIcon className={classes.extendedIcon} /> */}
                  BELI SEKARANG
                </Fab>
              </a>
            </div>
          </>
        ) : null}
        <div
          style={{
            margin: "0 auto",
            textAlign: "center",
            marginTop: "15px",
            paddingRight: "8px",
            marginBottom: "60px",
          }}
        >
          <InstagramEmbed
            url={embed}
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          />
        </div>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default App;
