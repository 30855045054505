import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Kategori from "../component/Kategori";
import Design from "../component/Design";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Preview from "../component/Preview";
import RefreshIcon from "@material-ui/icons/Refresh";
import introJs from "intro.js";
import "intro.js/introjs.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <div>{children}</div>}</div>;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const classes = useStyles();
  const tab = parseInt(localStorage.getItem("tab"));
  const [value, setValue] = useState(tab);
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const username = localStorage.getItem("username");
  const [backdrop, setBackdrop] = useState(false);
  const [endPro, setEndPro] = useState(false);
  const token = localStorage.getItem("api_token");
  const level = localStorage.getItem("level");
  const [myTheme, setMyTheme] = useState([]);
  const [preview, setPreview] = useState(true);
  const [grid_link, setGrid_link] = useState(3);
  const [tutorial, setTutorial] = useState(1);

  useEffect(() => {
    const token = localStorage.getItem("api_token");
    const code = localStorage.getItem("code");
    if (!token && !code) {
      setRedirect("login");
    } else if (token && !code) {
      setRedirect("auth");
    }
    document.body.style.backgroundImage = `url(${bg})`;
    getMyTheme();
    // introJs().start();
  }, []);

  useEffect(() => {
    axios
      .get("https://soosoon.id/backend/public/api/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.level) {
            localStorage.setItem("level", res.data.data.level);
          }
          setEndPro(res.data.data.end_pro);
          setGrid_link(res.data.data.grid_link);
          setTutorial(res.data.data.tutorial);
        }
      });
  });

  const getMyTheme = () => {
    axios
      .get("https://soosoon.id/backend/public/api/themes/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setMyTheme(res.data.data);
        }
      });
  };

  const handleClick = () => {
    document.execCommand("copy");
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue != 3) {
      localStorage.setItem("tab", newValue);
    }
  };

  const handleGridChange = (event, newValue) => {
    setGrid_link(newValue);
  };

  const handleTutorialChange = (event, newValue) => {
    setTutorial(newValue);
  };

  const handleLogout = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>Logout</p>,
      text: "Anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        localStorage.removeItem("api_token");
        localStorage.removeItem("code");
        localStorage.removeItem("email");
        localStorage.removeItem("username");
        localStorage.removeItem("level");
        localStorage.removeItem("tab");
        setRedirect("login");
      } else {
        setValue(tab);
      }
    });
  };

  const handlePayment = (duration, text_duration, price) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>PRO USER</p>,
      text: `Anda ingin menjadi PRO USER 1 ${text_duration}?`,
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        setBackdrop(true);
        axios
          .post("https://my.ipaymu.com/payment", {
            key: "4EC844B8-EB85-4D05-B5E7-918AF3D68B7D",
            product: `PRO USER 1 ${text_duration}`,
            price: price,
            quantity: 1,
            comments: `Anda akan mendapatkan tambahan link seupuasnya, tambahan kategori sepuasnya dan FREE 1 (satu) buah template setiap kami mengeluarkan Template pack baru!`,
            ureturn: "https://soosoon.id/admin",
            unotify:
              "https://soosoon.id/backend/public/api/transactions/notify",
            ucancel: "https://soosoon.id/admin",
            format: "json",
          })
          .then((res) => {
            if (res.data.sessionID) {
              axios
                .post(
                  "https://soosoon.id/backend/public/api/transactions",
                  {
                    sessionID: res.data.sessionID,
                    url: res.data.url,
                    type: 1,
                    theme_category_id: 0,
                    duration: duration,
                    price: price,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  handleRedirectPayment(1, res.data.url);
                });
            } else {
              handleRedirectPayment(0, "");
            }
            setBackdrop(false);
          });
      }
    });
  };

  const handleRedirectPayment = (status, url) => {
    const MySwal = withReactContent(Swal);
    if (status == 1) {
      MySwal.fire({
        title: <p>BERHASIL!</p>,
        text: "Satu Langkah Lagi Menjadi PRO USER",
        confirmButtonText: "Lanjut ke Pembayaran",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "container-class",
          popup: "popup-class",
          title: "title-class",
          content: "content-class",
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class",
        },
      }).then((res) => {
        if (res.value === true) {
          window.location = url;
        }
      });
    } else if (status == 0) {
      MySwal.fire({
        title: <p>Gagal</p>,
        text: "Terjadi kesalahan sistem, silakan coba kembali",
        showCancelButton: false,
        customClass: {
          container: "container-class",
          popup: "popup-class",
          title: "title-class",
          content: "content-class",
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class",
        },
      });
    }
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      {/* <Container> */}
      <Grid container spacing={0}>
        <Grid item sm={8} xs={12}>
          <Container maxWidth="sm">
            <div className="spacer-30"></div>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "70px", maxWidth: "100%", marginTop: 5 }}
                />
              </Grid>
              <Grid
                item
                sm={9}
                data-intro="Ini link kamu, klik untuk mengcopy dan tambahkan pada profil instagrammu!"
              >
                <p className="link">LINK ANDA:</p>
                <CopyToClipboard text={`soosoon.id/${username}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="linkkbtn"
                    onClick={handleClick}
                  >
                    soosoon.id/{username}
                  </Button>
                </CopyToClipboard>
                <p className="tips">
                  Tambahkan Link Pada Profil Instagram Anda
                </p>
              </Grid>
            </Grid>
          </Container>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Link berhasil disalin
            </Alert>
          </Snackbar>
          <div className="spacer-15"></div>
          <Container maxWidth="sm" disableGutters>
            <Paper className={classes.root} style={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="fullWidth"
                scrollButtons="off"
              >
                <Tab
                  label="SOOSOON"
                  data-intro="Tab ini untuk mengelola link dan kategori"
                />
                <Tab
                  label="DESIGN"
                  data-intro="Tab ini untuk mengubah template desain"
                />
                <Tab
                  label="PRO!"
                  data-intro="Lihat tab ini untuk menjadi pro user"
                />
                <Tab
                  label="LOGOUT"
                  className="preview"
                  onClick={() => handleLogout()}
                  data-intro="Klik tab ini untuk keluar dari aplikasi"
                />
              </Tabs>
            </Paper>
          </Container>
          <div className="spacer-30"></div>
          <Container maxWidth="sm">
            <TabPanel value={value} index={0}>
              <Kategori
                onGridChange={(value) => handleGridChange(0, value)}
                onTutorialChange={(value) => handleTutorialChange(0, value)}
                gridLink={grid_link}
                tutorial={tutorial}
                onCounterChange={(value) => handleChange(0, value)}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Design onCounterChange={(value) => handleChange(0, value)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="wrap">
                {level == 1 ? (
                  <>
                    <p>
                      Yuk Segera jadi PRO USER Soosoon! Yang kalian dapatkan:
                    </p>
                    <ul>
                      <li>Tambahan link sepuasnya!</li>
                      <li>Tambahan kategori sepuasnya!</li>
                      <li>
                        FREE 1 (satu) buah template setiap kami mengeluarkan
                        Template pack baru!
                      </li>
                    </ul>
                    <p>
                      Dan kami akan terus menambahkan fitur-fitur baru
                      kedepannya.
                      <br />
                      Kalian cukup menyisihkan jatah 1 gelas kopi kekinian
                      kalian setiap bulannya
                    </p>
                    <div className="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="button btn-sm button-primary"
                        style={{ marginBottom: "10px" }}
                        onClick={() => handlePayment("1", "bulan", 20000)}
                      >
                        IDR 20.000/bulan
                      </Button>
                    </div>
                    <p>
                      Atau kalian bisa membayar per tahun, dijamin lebih hemat!
                    </p>
                    <div className="center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="button btn-sm button-primary"
                        style={{ marginBottom: "10px" }}
                        onClick={() => handlePayment("2", "tahun", 200000)}
                      >
                        IDR 200.000/tahun
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {level == 2 ? (
                  <>
                    <p>
                      Selamat anda telah menjadi PRO USER Soosoon! Yang kalian
                      dapatkan:
                    </p>
                    <ul>
                      <li>Tambahan link sepuasnya!</li>
                      <li>Tambahan kategori sepuasnya!</li>
                      <li>
                        FREE 1 (satu) buah template setiap kami mengeluarkan
                        Template pack baru!
                      </li>
                    </ul>
                    <p>
                      Dan kami akan terus menambahkan fitur-fitur baru
                      kedepannya.
                    </p>
                    <p>
                      Anda menjadi PRO USER hingga: <br />
                      {endPro}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </TabPanel>
            <div className="center">
              <div className="spacer-60"></div>
              <div className="garis"></div>
              <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
            </div>
          </Container>
          <Backdrop
            style={{ zIndex: 99999999999 }}
            className={classes.backdrop}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
        <Grid item sm={4} className="dividerver">
          <div className="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className="button btn-sm button-secondary"
              style={{ marginTop: "20px", marginBottom: "20px" }}
              onClick={() => window.location.reload(false)}
            >
              PREVIEW&nbsp;&nbsp;
              <RefreshIcon />
            </Button>
          </div>
          <div className="previewmob">
            {preview && myTheme.id && (
              <Preview username={username} idPreview={myTheme.id} />
            )}
          </div>
        </Grid>
      </Grid>
      {/* </Container> */}
    </MuiThemeProvider>
  );
}

export default App;
