import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, TextField, Button } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameField, setUsernameField] = useState(false);
  const [passwordField, setPasswordField] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    handleRedirect();
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("api_token");
    const code = localStorage.getItem("code");
    if (token && code) {
      setRedirect("admin");
    }else if(token && !code){
      setRedirect('auth')
    }
  }

  const handleUsername = (e) => {
    setUsername(e.target.value.toLowerCase());
    setUsernameField(false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setPasswordField(false);
  };

  const submitLogin = () => {
    if (username === "") {
      setUsernameField(true);
    }
    if (password === "") {
      setPasswordField(true);
    }

    if (username && password) {
      setOpen(!open);
      axios
        .post("https://soosoon.id/backend/public/api/login", {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.data.status === true) {
            localStorage.setItem("email", res.data.data.email);
            localStorage.setItem("username", res.data.data.username);
            localStorage.setItem("api_token", res.data.data.api_token);
            localStorage.setItem("code", res.data.data.code);
            localStorage.setItem("level", res.data.data.level);
            localStorage.setItem("tab", 0);
            setOpen(false);   
            handleRedirect();         
          } else {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
              title: <p>Oops...</p>,
              text: "Your credentials is not matched",
              confirmButtonText: "Ok",
              customClass: {
                container: 'container-class',
                popup: 'popup-class',
                title: 'title-class',
                content: 'content-class',
                confirmButton: 'confirm-button-class',
                cancelButton: 'cancel-button-class',
              }
            });
            setOpen(false);
          }
          setOpen(false);
        })
        .catch((e) => {
          const MySwal = withReactContent(Swal);
            MySwal.fire({
              title: <p>Oops...</p>,
              text: "Your credentials is not matched",
              confirmButtonText: "Ok",
              customClass: {
                container: 'container-class',
                popup: 'popup-class',
                title: 'title-class',
                content: 'content-class',
                confirmButton: 'confirm-button-class',
                cancelButton: 'cancel-button-class',
              }
            });
          setOpen(false);
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <p>LOGIN UNTUK MELANJUTKAN</p>
        </div>
        <div className="spacer-15"></div>
        <div className="form">
          <TextField
            label="Username"
            color="primary"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handleUsername(e)}
            error={usernameField}
            value={username}
          />
          <TextField
            label="Password"
            type="password"
            color="primary"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handlePassword(e)}
            error={passwordField}
          />
          <div className="spacer-30"></div>
          <div className="center">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => submitLogin()}
            >
              MASUK
            </Button>
          </div>
          <div className="spacer-30"></div>
          <div className="center">
            <Link to="/forgot-password">Lupa Password?</Link>
          </div>
        </div>
        <div className="spacer-30"></div>
        <div className="center">
          <p>
            atau <Link to="/register">SIGN UP</Link> bila belum memiliki akun
          </p>
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
