import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, TextField, Button } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router";
import queryString from "query-string";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordField, setPasswordField] = useState(false);
  const [passwordConfirmationField, setPasswordConfirmationField] = useState(
    false
  );
  const [helperPassword, setHelperPassword] = useState("");
  let location = useLocation();

  useEffect(() => {
    handleRedirect();
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("api_token");
    const code = localStorage.getItem("code");
    if (token && code) {
      setRedirect("admin");
    } else if (token && !code) {
      setRedirect("auth");
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setPasswordField(false);
    setHelperPassword("");
  };

  const handlePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value);
    setPasswordConfirmationField(false);
    setHelperPassword("");
  };

  const submitReset = () => {
    if (password === "") {
      setPasswordField(true);
    }

    if (passwordConfirmation === "") {
      setPasswordConfirmationField(true);
    }
    if (password && passwordConfirmation) {
      setOpen(!open);
      const id = queryString.parse(location.search).token;
      axios
        .post("https://soosoon.id/backend/public/api/update-password", {
          id: id,
          password: password,
          password_confirmation: passwordConfirmation,
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            const MySwal = withReactContent(Swal);
            MySwal.fire({
              title: <p>Info</p>,
              text: "Password berhasil terubah, silakan login kembali!",
              confirmButtonText: "Ok",
              customClass: {
                container: "container-class",
                popup: "popup-class",
                title: "title-class",
                content: "content-class",
                confirmButton: "confirm-button-class",
                cancelButton: "cancel-button-class",
              },
            }).then((res) => {
              window.location = "/login";
            });
          } else {
            const resPassword = res.data.data.password;

            if (resPassword) {
              setPasswordField(true);
              setPasswordConfirmationField(true);
              setHelperPassword(resPassword[0]);
            }
            setOpen(false);
          }
          setOpen(false);
        })
        .catch((e) => {
          const resPassword = e.response.data.data.password;
          if (resPassword) {
            setPasswordField(true);
            setPasswordConfirmationField(true);
            setHelperPassword(resPassword[0]);
          }
          setOpen(false);
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <p>RESET PASSWORD</p>
        </div>
        <div className="spacer-60"></div>
        <div className="form">
          <TextField
            label="Password"
            color="primary"
            type="password"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handlePassword(e)}
            error={passwordField}
            helperText={helperPassword}
          />
          <TextField
            label="Ulangi Password"
            color="primary"
            type="password"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handlePasswordConfirmation(e)}
            error={passwordConfirmationField}
          />
          <div className="spacer-30"></div>
          <div className="center">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => submitReset()}
            >
              RESET PASSWORD
            </Button>
          </div>
        </div>
        <div className="spacer-30"></div>
        <div className="center">
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
