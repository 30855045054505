import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, TextField, Button } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [emailField, setEmailField] = useState(false);
  const [usernameField, setUsernameField] = useState(false);
  const [passwordField, setPasswordField] = useState(false);
  const [passwordConfirmationField, setPasswordConfirmationField] = useState(
    false
  );
  const [checkbox, setCheckbox] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [helperEmail, setHelperEmail] = useState("");
  const [helperUsername, setHelperUsername] = useState("");
  const [helperPassword, setHelperPassword] = useState("");

  useEffect(() => {
    handleRedirect();
      document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("api_token");
    const code = localStorage.getItem("code");
    if (token && code) {
      setRedirect("admin");
    } else if (token && !code) {
      setRedirect("auth");
    }
  };

  const handleCekbox = (event) => {
    setCheckbox(event.target.checked);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
    setEmailField(false);
    setHelperEmail("");
  };

  const handleUsername = (e) => {
    setUsername(e.target.value.toLowerCase());
    setUsernameField(false);
    setHelperUsername("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setPasswordField(false);
    setHelperPassword("");
  };

  const handlePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value);
    setPasswordConfirmationField(false);
    setHelperPassword("");
  };

  const submitRegister = () => {
    if (email === "") {
      setEmailField(true);
    }

    if (username === "") {
      setUsernameField(true);
    }

    if (password === "") {
      setPasswordField(true);
    }

    if (passwordConfirmation === "") {
      setPasswordConfirmationField(true);
    }

    if (email && username && password && passwordConfirmation && checkbox) {
      setOpen(!open);
      axios
        .post("https://soosoon.id/backend/public/api/register", {
          email: email,
          username: username,
          password: password,
          password_confirmation: passwordConfirmation,
        })
        .then((res) => {
          if (res.data.status === true) {
            localStorage.setItem("email", res.data.data.email);
            localStorage.setItem("username", res.data.data.username);
            localStorage.setItem("api_token", res.data.data.api_token);
            localStorage.setItem("level", res.data.data.level);
            localStorage.setItem("tab", 0);
            setOpen(false);
            handleRedirect();
          } else {
            setOpen(false);
          }
          setOpen(false);
        })
        .catch((e) => {
          setOpen(false);
          const resEmail = e.response.data.data.email;
          const resUsername = e.response.data.data.username;
          const resPassword = e.response.data.data.password;

          if (resEmail) {
            setEmailField(true);
            setHelperEmail(resEmail);
          }

          if (resUsername) {
            setUsernameField(true);
            setHelperUsername(resUsername);
          }

          if (resPassword) {
            setPasswordField(true);
            setPasswordConfirmationField(true);
            setHelperPassword(resPassword[0]);
          }
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <p>SIGN UP UNTUK MEMULAI</p>
        </div>
        <div className="spacer-15"></div>
        <div className="form">
          <TextField
            label="Email"
            color="primary"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handleEmail(e)}
            error={emailField}
            helperText={helperEmail}
            value={email}
          />
          <TextField
            label="Username Instagram"
            color="primary"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handleUsername(e)}
            error={usernameField}
            helperText={helperUsername ? helperUsername : 'Tanpa simbol @'}
            value={username}
          />
          <TextField
            label="Password"
            color="primary"
            type="password"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handlePassword(e)}
            error={passwordField}
            helperText={helperPassword}
          />
          <TextField
            label="Ulangi Password"
            color="primary"
            type="password"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handlePasswordConfirmation(e)}
            error={passwordConfirmationField}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={handleCekbox}
                color="primary"
              />
            }
            label="Saya Mengerti Syarat dan Ketentuan"
            style={{marginTop:'10px'}}
          />
          <div className="spacer-30"></div>
          <div className="center">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => submitRegister()}
            >
              DAFTAR
            </Button>
          </div>
        </div>
        <div className="spacer-30"></div>
        <div className="center">
          <p>
            atau <Link to="/login">LOGIN</Link> bila sudah memiliki akun
          </p>
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
