import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, TextField, Button } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailField, setEmailField] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    handleRedirect();
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("api_token");
    const code = localStorage.getItem("code");
    if (token && code) {
      setRedirect("admin");
    } else if (token && !code) {
      setRedirect("auth");
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
    setEmailField(false);
  };

  const submitReset = () => {
    if (email === "") {
      setEmailField(true);
    }
    if (email) {
      setOpen(!open);
      axios
        .post("https://soosoon.id/backend/public/api/reset-password", {
          email: email,
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            const MySwal = withReactContent(Swal);
            MySwal.fire({
                title: <p>Info</p>,
                text: 'Kami telah mengirimkan anda email untuk mereset password anda. Tidak menerima email? Periksa folder spam anda atau kirim ulang email.',
                confirmButtonText: "Ok",
                customClass: {
                  container: "container-class",
                  popup: "popup-class",
                  title: "title-class",
                  content: "content-class",
                  confirmButton: "confirm-button-class",
                  cancelButton: "cancel-button-class",
                },
              }).then((res) => {
                  window.location = '/login';
              });
          } else {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
              title: <p>Oops...</p>,
              text: res.data.message,
              confirmButtonText: "Ok",
              customClass: {
                container: "container-class",
                popup: "popup-class",
                title: "title-class",
                content: "content-class",
                confirmButton: "confirm-button-class",
                cancelButton: "cancel-button-class",
              },
            });
            setOpen(false);
          }
          setOpen(false);
        })
        .catch((e) => {
          const MySwal = withReactContent(Swal);
          MySwal.fire({
            title: <p>Oops...</p>,
            text: "Akun anda tidak ditemukan",
            confirmButtonText: "Ok",
            customClass: {
              container: "container-class",
              popup: "popup-class",
              title: "title-class",
              content: "content-class",
              confirmButton: "confirm-button-class",
              cancelButton: "cancel-button-class",
            },
          });
          setOpen(false);
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <p>LUPA PASSWORD</p>
          <p style={{ padding: "10px 30px" }}>
            Masukkan email/username anda untuk menerima email pengaturan ubah password.
          </p>
        </div>
        <div className="spacer-15"></div>
        <div className="form">
          <TextField
            label="Email/username"
            color="primary"
            fullWidth
            size="small"
            margin="normal"
            className="input"
            onChange={(e) => handleEmail(e)}
            error={emailField}
            value={email}
          />
          <div className="spacer-30"></div>
          <div className="center">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => submitReset()}
            >
              RESET PASSWORD
            </Button>
          </div>
        </div>
        <div className="spacer-30"></div>
        <div className="center">
          <p>
            kembali <Link to="/login">LOGIN</Link>
          </p>
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
