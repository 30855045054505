import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

function App() {
  const [active, setActive] = useState("id");
  const changeActive = (newValue) => {
    setActive(newValue);
  };
  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);
  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Link to="/">
          <ArrowBackIcon
            color="primary"
            style={{
              color: "#0266ba",
              marginTop: "15px",
              marginBottom: "-24px",
            }}
            fontSize="large"
          />
        </Link>
        <div className="center">
          <img src={logo} alt="logo" />
          <h1 style={{ fontSize: "16px" }}>PRIVACY POLICIES</h1>
        </div>
        <div className="spacer-15"></div>
        <div>
          <div className="wrap">
            <ul className="lang">
              <li
                onClick={() => changeActive("id")}
                className={active === "id" ? "langactive" : ""}
              >
                ID
              </li>
              <li
                onClick={() => changeActive("en")}
                className={active === "en" ? "langactive" : ""}
              >
                EN
              </li>
            </ul>
            {active === "id" && (
              <>
                <p>
                  Dengan menggunakan layanan kami, Anda memercayakan informasi
                  Anda kepada kami. Kami paham bahwa melindungi informasi Anda
                  dan memberikan kontrol kepada Anda adalah tanggung jawab yang
                  besar dan memerlukan kerja keras.
                  <br />
                  <br />
                  Halaman ini memberi tahu Anda tentang kebijakan kami mengenai
                  pengumpulan, penggunaan, dan pengungkapan data pribadi saat
                  Anda menggunakan Layanan kami dan pilihan yang telah Anda
                  kaitkan dengan data itu. Kebijakan Privasi untuk SooSoon kami
                  didasarkan pada Situs Web Template Kebijakan Privasi Gratis.
                  <br />
                  <br />
                  Kami menggunakan data Anda untuk menyediakan dan meningkatkan
                  Layanan. Dengan menggunakan Layanan, Anda menyetujui
                  pengumpulan dan penggunaan informasi sesuai dengan kebijakan
                  ini. Kecuali ditentukan lain dalam Kebijakan Privasi ini,
                  istilah yang digunakan dalam Kebijakan Privasi ini memiliki
                  arti yang sama seperti dalam Syarat dan Ketentuan kami.
                  <br />
                  <br />
                  Pengumpulan Dan Penggunaan Informasi :<br /> Kami mengumpulkan
                  beberapa jenis informasi yang berbeda untuk berbagai keperluan
                  untuk menyediakan dan meningkatkan Layanan kami kepada Anda.
                  <br />
                  <br />
                  Jenis Data yang Dikumpulkan :
                </p>
                <h2>1. Data Pribadi</h2>
                <p>
                  Saat menggunakan Layanan kami, kami mungkin meminta Anda untuk
                  memberikan kami informasi pengenal pribadi tertentu yang dapat
                  digunakan untuk menghubungi atau mengidentifikasi Anda (“Data
                  Pribadi”). Informasi yang dapat diidentifikasi secara pribadi
                  dapat mencakup, tetapi tidak terbatas pada:
                </p>
                <ul>
                  <li>Alamat Email</li>
                  <li>Nama depan dan nama belakang</li>
                  <li>Nomor telepon</li>
                  <li>Alamat, Provinsi, ZIP / Kode pos, Kota</li>
                  <li>Cookie dan Data Penggunaan</li>
                </ul>
                <h2>2. Data Penggunaan</h2>
                <p>
                  Saat Anda mengakses Layanan dengan atau melalui perangkat
                  seluler, kami dapat mengumpulkan informasi tertentu secara
                  otomatis, termasuk, namun tidak terbatas pada, jenis perangkat
                  seluler yang Anda gunakan, ID unik perangkat seluler Anda,
                  alamat IP perangkat seluler Anda, ponsel Anda sistem operasi,
                  jenis browser Internet seluler yang Anda gunakan,
                  pengidentifikasi perangkat unik dan data diagnostik lainnya
                  (“Data Penggunaan”).
                </p>
                <h2>3. Pelacakan & Data Cookie</h2>
                <p>
                  Kami menggunakan cookie dan teknologi pelacakan serupa untuk
                  melacak aktivitas di Layanan kami dan menyimpan informasi
                  tertentu.
                  <br />
                  <br />
                  Cookie adalah file dengan sedikit data yang dapat menyertakan
                  pengidentifikasi unik anonim. Cookie dikirim ke browser Anda
                  dari situs web dan disimpan di perangkat Anda. Teknologi
                  pelacakan yang juga digunakan adalah suar, tag, dan skrip
                  untuk mengumpulkan dan melacak informasi dan untuk
                  meningkatkan dan menganalisis Layanan kami.
                  <br />
                  <br />
                  Anda dapat menginstruksikan browser Anda untuk menolak semua
                  cookie atau untuk menunjukkan kapan cookie dikirim. Namun,
                  jika Anda tidak menerima cookie, Anda mungkin tidak dapat
                  menggunakan sebagian Layanan kami.
                  <br />
                  <br />
                  Contoh Cookie yang kami gunakan:
                  <br />
                  Cookie Sesi : Kami menggunakan Cookie Sesi untuk
                  mengoperasikan Layanan kami.
                  <br />
                  Cookie Pilihan : Kami menggunakan Cookie Preferensi untuk
                  mengingat preferensi Anda dan berbagai pengaturan.
                  <br />
                  Cookie Keamanan : Kami menggunakan Cookie Keamanan untuk
                  tujuan keamanan.
                </p>
                <h2>4. Penggunaan Data</h2>
                <p>
                  SooSoon menggunakan data yang dikumpulkan untuk berbagai
                  tujuan untuk :
                </p>
                <ul>
                  <li>Menyediakan dan memelihara Layanan</li>
                  <li>
                    Memberitahukan Anda tentang perubahan didalam pelayanan kami
                  </li>
                  <li>
                    Memungkinkan Anda berpartisipasi dalam fitur interaktif
                    Layanan kami ketika Anda memilih untuk melakukannya
                  </li>
                  <li>Memberikan layanan dan dukungan pelanggan</li>
                  <li>
                    Memberikan analisis atau informasi berharga sehingga kami
                    dapat meningkatkan Layanan
                  </li>
                  <li>Memantau penggunaan Layanan</li>
                  <li>Mendeteksi, mencegah dan mengatasi masalah teknis</li>
                </ul>
                <h2>5. Transfer Data</h2>
                <p>
                  Informasi Anda, termasuk Data Pribadi, dapat ditransfer ke –
                  dan dipelihara di – komputer yang berlokasi di luar negara
                  bagian, provinsi, negara atau yurisdiksi pemerintah lainnya di
                  mana undang-undang perlindungan data mungkin berbeda dari yang
                  ada di yurisdiksi Anda.
                  <br />
                  <br />
                  Jika Anda berada di luar Indonesia dan memilih untuk
                  memberikan informasi kepada kami, harap perhatikan bahwa kami
                  mentransfer data, termasuk Data Pribadi, ke Indonesia dan
                  memprosesnya di sana.
                  <br />
                  <br />
                  Persetujuan Anda untuk Kebijakan Privasi ini diikuti dengan
                  pengajuan informasi tersebut merupakan persetujuan Anda untuk
                  transfer tersebut.
                  <br />
                  <br />
                  SooSoon akan mengambil semua langkah yang wajar diperlukan
                  untuk memastikan bahwa data Anda diperlakukan dengan aman dan
                  sesuai dengan Kebijakan Privasi ini dan tidak ada transfer
                  Data Pribadi Anda yang akan terjadi pada suatu organisasi atau
                  negara kecuali jika ada kontrol yang memadai di tempat
                  termasuk keamanan Anda data dan informasi pribadi lainnya.
                </p>
                <h2>6. Keterbukaan Data</h2>
                <p>
                  Persyaratan resmi
                  <br />
                  SooSoon dapat mengungkapkan Data Pribadi Anda dengan itikad
                  baik bahwa tindakan tersebut diperlukan untuk :<br />
                </p>
                <ul>
                  <li>Mematuhi kewajiban hukum</li>
                  <li>
                    Melindungi dan mempertahankan hak atau properti SooSoon
                  </li>
                  <li>
                    Mencegah atau menyelidiki kesalahan yang mungkin terjadi
                    sehubungan dengan Layanan
                  </li>
                  <li>
                    Melindungi keamanan pribadi pengguna Layanan atau publik
                  </li>
                  <li>Melindungi terhadap tanggung jawab hukum</li>
                </ul>
                <h2>7. Keamanan Data</h2>
                <p>
                  Keamanan data Anda penting bagi kami, tetapi ingat bahwa tidak
                  ada metode transmisi melalui Internet, atau metode penyimpanan
                  elektronik yang 100% aman. Meskipun kami berusaha untuk
                  menggunakan cara yang dapat diterima secara komersial untuk
                  melindungi Data Pribadi Anda, kami tidak dapat menjamin
                  keamanan mutlaknya.
                </p>
                <h2>8. Penyedia jasa</h2>
                <p>
                  Kami dapat mempekerjakan perusahaan pihak ketiga dan individu
                  untuk memfasilitasi Layanan kami (“Penyedia Layanan”), untuk
                  menyediakan Layanan atas nama kami, untuk melakukan layanan
                  terkait Layanan atau untuk membantu kami dalam menganalisis
                  bagaimana Layanan kami digunakan.
                  <br />
                  <br />
                  Pihak ketiga ini memiliki akses ke Data Pribadi Anda hanya
                  untuk melakukan tugas-tugas ini atas nama kami dan
                  berkewajiban untuk tidak mengungkapkan atau menggunakannya
                  untuk tujuan lain apa pun.
                </p>
                <h2>9. Analisis</h2>
                <p>
                  Kami dapat menggunakan Penyedia Layanan pihak ketiga untuk
                  memantau dan menganalisis penggunaan Layanan kami.
                </p>
                <h2>10. Google Analytics</h2>
                <p>
                  Google Analytics adalah layanan analisis web yang ditawarkan
                  oleh Google yang melacak dan melaporkan lalu lintas situs web.
                  Google menggunakan data yang dikumpulkan untuk melacak dan
                  memantau penggunaan Layanan kami. Data ini dibagikan dengan
                  layanan Google lainnya. Google dapat menggunakan data yang
                  dikumpulkan untuk mengontekstualisasikan dan
                  mempersonalisasikan iklan dari jaringan iklannya sendiri.
                  <br />
                  <br />
                  Anda dapat memilih keluar dari fitur Google Analytics tertentu
                  melalui pengaturan perangkat seluler Anda, seperti pengaturan
                  iklan perangkat Anda atau dengan mengikuti instruksi yang
                  diberikan oleh Google dalam Kebijakan Privasi mereka:
                  https://policies.google.com/privacy?hl=id
                  <br />
                  <br />
                  Untuk informasi lebih lanjut tentang praktik privasi Google,
                  silakan kunjungi halaman web Google Privacy & Terms:
                  https://policies.google.com/privacy?hl=id
                  <br />
                  <br />
                </p>
                <h2>10. Tautan ke Situs Lain</h2>
                <p>
                  Layanan kami dapat berisi tautan ke situs lain yang tidak
                  dioperasikan oleh kami. Jika Anda mengklik tautan pihak
                  ketiga, Anda akan diarahkan ke situs pihak ketiga itu. Kami
                  sangat menyarankan Anda untuk meninjau Kebijakan Privasi
                  setiap situs yang Anda kunjungi.
                  <br />
                  <br />
                  Kami tidak memiliki kendali atas dan tidak bertanggung jawab
                  atas konten, kebijakan privasi, atau praktik situs atau
                  layanan pihak ketiga mana pun.
                </p>
                <h2>11. Privasi anak-anak</h2>
                <p>
                  Layanan kami tidak membahas siapa pun di bawah usia 18
                  (“Anak-anak”).
                  <br />
                  <br />
                  Kami tidak secara sadar mengumpulkan informasi yang dapat
                  diidentifikasi secara pribadi dari siapa pun yang berusia di
                  bawah 18 tahun. Jika Anda adalah orang tua atau wali dan Anda
                  mengetahui bahwa Anak-anak Anda telah memberikan kepada kami
                  Data Pribadi, silakan hubungi kami. Jika kami mengetahui bahwa
                  kami telah mengumpulkan Data Pribadi dari anak-anak tanpa
                  verifikasi izin orang tua, kami mengambil langkah-langkah
                  untuk menghapus informasi itu dari server kami.
                </p>
                <h2>12. Perubahan Terhadap Kebijakan Privasi Ini</h2>
                <p>
                  Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke
                  waktu. Kami akan memberi tahu Anda tentang segala perubahan
                  dengan memposting Kebijakan Privasi baru di halaman ini.
                  <br />
                  <br />
                  Kami akan memberi tahu Anda melalui email dan / atau
                  pemberitahuan penting pada Layanan kami, sebelum perubahan
                  menjadi efektif dan memperbarui “tanggal efektif” di bagian
                  atas Kebijakan Privasi ini.
                  <br />
                  <br />
                  Anda disarankan untuk meninjau Kebijakan Privasi ini secara
                  berkala untuk setiap perubahan. Perubahan pada Kebijakan
                  Privasi ini efektif ketika diposkan pada halaman ini.
                </p>
                <h2>13. Hubungi kami</h2>
                <p>
                  Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini,
                  silakan hubungi kami:
                  <br />
                  email: info@soosoon.id
                  <br />
                  <br />
                  PT. MMT International Indonesia
                  <br />
                  Grab Essentials Group
                  <br />
                  Rasuna Office Park JR.04
                  <br />
                  <br />
                  Jakarta 12960
                  <br />
                  <br />
                  Indonesia
                </p>
              </>
            )}
            {active === "en" && (
              <>
                <p>
                  SOOSOON operates the soosoon.id website, which provides the
                  SERVICE.
                  <br />
                  <br />
                  This page is used to inform website visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service, the SOOSOON
                  website.
                  <br />
                  <br />
                  If you choose to use our Service, then you agree to the
                  collection and use of information in relation with this
                  policy. The Personal Information that we collect are used for
                  providing and improving the Service. We will not use or share
                  your information with anyone except as described in this
                  Privacy Policy.
                  <br />
                  <br />
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which is accessible at
                  soosoon.id, unless otherwise defined in this Privacy Policy.
                  Our Privacy Policy was created with the help of the Privacy
                  Policy Template and the Disclaimer Generator.
                </p>
                <h2>Information Collection and Use</h2>
                <p>
                  For a better experience while using our Service, we may
                  require you to provide us with certain personally identifiable
                  information, including but not limited to your name, phone
                  number, and postal address. The information that we collect
                  will be used to contact or identify you.
                </p>
                <h2>Log Data</h2>
                <p>
                  We want to inform you that whenever you visit our Service, we
                  collect information that your browser sends to us that is
                  called Log Data. This Log Data may include information such as
                  your computer’s Internet Protocol ("IP") address, browser
                  version, pages of our Service that you visit, the time and
                  date of your visit, the time spent on those pages, and other
                  statistics.
                </p>
                <h2>Cookies</h2>
                <p>
                  Cookies are files with small amount of data that is commonly
                  used an anonymous unique identifier. These are sent to your
                  browser from the website that you visit and are stored on your
                  computer’s hard drive.
                  <br />
                  <br />
                  Our website uses these "cookies" to collection information and
                  to improve our Service. You have the option to either accept
                  or refuse these cookies, and know when a cookie is being sent
                  to your computer. If you choose to refuse our cookies, you may
                  not be able to use some portions of our Service.
                  <br />
                  <br />
                  For more general information on cookies, please read "What Are
                  Cookies".
                </p>
                <h2>Service Providers</h2>
                <p>
                  We may employ third-party companies and individuals due to the
                  following reasons:
                </p>
                <ul>
                  <li>To facilitate our Service;</li>
                  <li>To provide the Service on our behalf;</li>
                  <li>To perform Service-related services; or</li>
                  <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>
                  We want to inform our Service users that these third parties
                  have access to your Personal Information. The reason is to
                  perform the tasks assigned to them on our behalf. However,
                  they are obligated not to disclose or use the information for
                  any other purpose.
                </p>
                <h2>Security</h2>
                <p>
                  We value your trust in providing us your Personal Information,
                  thus we are striving to use commercially acceptable means of
                  protecting it. But remember that no method of transmission
                  over the internet, or method of electronic storage is 100%
                  secure and reliable, and we cannot guarantee its absolute
                  security.
                </p>
                <h2>Links to Other Sites</h2>
                <p>
                  Our Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us. Therefore,
                  we strongly advise you to review the Privacy Policy of these
                  websites. We have no control over, and assume no
                  responsibility for the content, privacy policies, or practices
                  of any third-party sites or services.
                  <br />
                  <br />
                  Children's Privacy
                  <br />
                  <br />
                  Our Services do not address anyone under the age of 13. We do
                  not knowingly collect personal identifiable information from
                  children under 13. In the case we discover that a child under
                  13 has provided us with personal information, we immediately
                  delete this from our servers. If you are a parent or guardian
                  and you are aware that your child has provided us with
                  personal information, please contact us so that we will be
                  able to do necessary actions.
                </p>
                <h2>Changes to This Privacy Policy</h2>
                <p>
                  We may update our Privacy Policy from time to time. Thus, we
                  advise you to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page. These changes are effective immediately,
                  after they are posted on this page.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us.
                  <br />
                  email: info@soosoon.id
                  <br />
                  <br />
                  PT. MMT International Indonesia
                  <br />
                  Grab Essentials Group
                  <br />
                  Rasuna Office Park JR.04
                  <br />
                  <br />
                  Jakarta 12960
                  <br />
                  <br />
                  Indonesia
                </p>
              </>
            )}
          </div>
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="center">&copy; Copyright 2020 Grab Essentials</p>
        </div>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
