import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../img/logo.png";
import bg from "../img/bg.png";
import InstagramIcon from "@material-ui/icons/Instagram";

import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from "query-string";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clientId, setClientId] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const [code, setCode] = useState("");
  const [redirect, setRedirect] = useState(false);
  const token = localStorage.getItem("api_token");
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("api_token");
    const code_ig = localStorage.getItem("code");
    if (token && code_ig) {
      setRedirect("admin");
    } else if (!token && !code_ig) {
      setRedirect("login");
    }    
  });

  useEffect(() => {
    if(clientId == "" && redirectUri == ""){
      setOpen(true);
    }

    axios
      .get("https://soosoon.id/backend/public/api/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.code) {
            localStorage.setItem("code", res.data.data.code);
          }
        }
      });

    const code = queryString.parse(location.search).code;
    if (code) {
      setCode(code);
      setOpen(false);
    } else {
      axios
        .get("https://soosoon.id/backend/public/api/ig_settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setClientId(res.data.data.client_id);
            setRedirectUri(res.data.data.redirect_uri);
            setOpen(false);
          }
        });
    }
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const submitCode = () => {
    setOpen(!open);
    axios
      .post(
        "https://soosoon.id/backend/public/api/oauth",
        { code: code },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === true) {
          setOpen(false);
          localStorage.setItem("code", code);
          setRedirect("admin");
        } else {
          setOpen(false);
        }
      })
      .catch((e) => {
        setOpen(false);
      });
  };

  if (redirect) {
    return <Redirect to={`/${redirect}`} />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <p>OTENTIKASI INSTAGRAM</p>
        </div>
        <div className="spacer-15"></div>
        <div className="form form-otentikasi">
          {!code && (
            <a
              href={`https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="button"
                startIcon={<InstagramIcon />}
              >
                OTENTIKASI INSTAGRAM
              </Button>
            </a>
          )}
          {code && (
            <div style={{ textAlign: "center", marginTop: "-28px" }}>
              <p>Berhasil Terotentikasi</p>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="button"
                onClick={() => submitCode()}
              >
                Lanjutkan
              </Button>
            </div>
          )}
        </div>
        <div className="spacer-30"></div>
        <div className="center">
          <div className="spacer-60"></div>
          <div className="garis"></div>
          <p className="copyright">&copy; Copyright 2020 Grab Essentials</p>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
