import React from "react";
import Register from "./component/Register";
import Login from "./component/Login";
import Auth from "./component/Auth";
import Admin from "./component/Admin";
import Home from "./component/Home";
import User from "./component/User";
import Privacy from "./component/Privacy";
import Syarat from "./component/Syarat";
import Forgot from "./component/Forgot";
import Reset from "./component/Reset";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-172487887-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/privacy-policies" exact component={Privacy} />
        <Route path="/terms-and-conditions" exact component={Syarat} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={Forgot} />
        <Route path="/reset-password" component={Reset} />
        <Route path="/auth" component={Auth} />
        <Route path="/admin" component={Admin} />
        <Route path="/:username" exact component={User} />
      </Switch>
    </Router>
  );
}

export default App;
