import React, { useState, useEffect } from "react";
import "../App.css";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import Preview from "../component/Preview";
import lock from "../img/lock.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: '43px',
    textAlign:'center',
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [themes, setThemes] = useState([]);
  const [myTheme, setMyTheme] = useState([]);
  const [idPreview, setIdPreview] = useState(1);
  const token = localStorage.getItem("api_token");
  const level = localStorage.getItem("level");
  const username = localStorage.getItem("username");

  useEffect(() => {
    getSliders();
    getThemes();
    getMyTheme();
  }, []);

  const getSliders = () => {
    axios
      .get("https://soosoon.id/backend/public/api/sliders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setSliders(res.data.data);
        }
      });
  };

  const getThemes = () => {
    axios
      .get("https://soosoon.id/backend/public/api/themes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setThemes(res.data.data);
        }
      });
  };

  const getMyTheme = () => {
    axios
      .get("https://soosoon.id/backend/public/api/themes/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setMyTheme(res.data.data);
        }
      });
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdPreview(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSimpanDesign = () => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/users/theme`,
        {
          theme_id: idPreview,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOpen(false);
        const MySwal = withReactContent(Swal);
        if (res.data.title == "Oops...") {
          MySwal.fire({
            title: `<p>${res.data.title}</p>`,
            text: res.data.message,
            showCancelButton: true,
            confirmButtonText: "Beli",
            cancelButtonText: "Design Lain",
            customClass: {
              container: 'container-class',
              popup: 'popup-class',
              title: 'title-class',
              content: 'content-class',
              confirmButton: 'confirm-button-class',
              cancelButton: 'cancel-button-class',
            }
          }).then((v) => {
            if (v.value === true) {
              handlePayment(res.data.theme_id, res.data.theme, res.data.price)
            }
          });
        } else {
          MySwal.fire({
            title: `<p>${res.data.title}</p>`,
            text: res.data.message,
            customClass: {
              container: 'container-class',
              popup: 'popup-class',
              title: 'title-class',
              content: 'content-class',
              confirmButton: 'confirm-button-class',
              cancelButton: 'cancel-button-class',
            }
          });
        }
        getThemes();
        getMyTheme();
      });
  };

  const handlePayment = (theme_id, theme, price) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `<p>${theme}</p>`,
      text: "Anda ingin membeli PREMIUM TEMPLATE ini?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        title: 'title-class',
        content: 'content-class',
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class',
      }
    }).then((res) => {
      if (res.value === true) {
        setBackdrop(true);
        axios
          .post("https://my.ipaymu.com/payment", {
            key: "4EC844B8-EB85-4D05-B5E7-918AF3D68B7D",
            product: theme,
            price: price,
            quantity: 1,
            comments: `Anda akan mendapatkan 3 pilihan design dengan membeli PREMIUM TEMPLATE ${theme}!`,
            ureturn: "https://soosoon.id/admin",
            unotify:
              "https://soosoon.id/backend/public/api/transactions/notify",
            ucancel: "https://soosoon.id/admin",
            format: "json",
          })
          .then((res) => {
            if (res.data.sessionID) {
              axios
                .post(
                  "https://soosoon.id/backend/public/api/transactions",
                  {
                    sessionID: res.data.sessionID,
                    url: res.data.url,
                    type: 0,
                    theme_category_id: theme_id,
                    duration: 0,
                    price: price,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  handleRedirectPayment(1, res.data.url);
                });
            } else {
              handleRedirectPayment(0, "");
            }
            setBackdrop(false);
          });
      }
    });
  };

  const handleRedirectPayment = (status, url) => {
    const MySwal = withReactContent(Swal);
    if (status == 1) {
      MySwal.fire({
        title: <p>BERHASIL!</p>,
        text:
          "Satu Langkah Lagi untuk memiliki PREMIUM TEMPLATE ini!",
        confirmButtonText: "Lanjut ke Pembayaran",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'container-class',
          popup: 'popup-class',
          title: 'title-class',
          content: 'content-class',
          confirmButton: 'confirm-button-class',
          cancelButton: 'cancel-button-class',
        }
      }).then((res) => {
        if (res.value === true) {
          window.location = url;
        }
      });
    } else if (status == 0) {
      MySwal.fire({
        title: <p>Oops..</p>,
        text: "Terjadi kesalahan sistem, silakan coba kembali!",
        showCancelButton: false,
        customClass: {
          container: 'container-class',
          popup: 'popup-class',
          title: 'title-class',
          content: 'content-class',
          confirmButton: 'confirm-button-class',
          cancelButton: 'cancel-button-class',
        }
      });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className="forcefull">
        <Carousel
          clickToChange
          slidesPerPage={2}
          centered
          keepDirectionWhenDragging
        >
          {sliders.length === 0 ? (
            <Skeleton
              style={{ marginTop: -30, borderRadius: "15px" }}
              animation="wave"
              width={300}
              height={200}
            />
          ) : (
            sliders.map((value, index) => {
              return (
                value.link != '' ?(
                <img
                  src={value.img}
                  key={index}
                  alt="slider"    
                  onClick={() => props.onCounterChange(parseInt(value.link))}            
                  className="carouselimg"
                />) :
                (<img
                  src={value.img}
                  key={index}
                  alt="slider"                  
                  className="carouselimg"
                />)
              );
            })
          )}
        </Carousel>
      </div>
      <div className="garis"></div>
      <p className="info">DESIGN YANG SEDANG DIGUNAKAN</p>
      <Grid container spacing={2} className="center">
        <Grid item xs={4}>
          {myTheme.length === 0 ? (
            <Skeleton
              style={{ marginTop: -30, borderRadius: "15px" }}
              animation="wave"
              height={150}
            />
          ) : (
            <div
              className="design"
              onClick={() => handleClickOpen(`${myTheme.id}`)}
              style={{ backgroundImage: `url(${myTheme.thumbnail})` }}
            >
              <p
                style={{
                  color: `${myTheme.primary_color}`,
                  textTransform: "uppercase",
                }}
              >
                {myTheme.name}
              </p>
              <span
                className="designcolor"
                style={{
                  backgroundColor: `${myTheme.button_color}`,
                  boxShadow: `3px 3px 5px ${myTheme.box_shadow_color}`,
                  borderColor: `${myTheme.border_color}`,
                }}
              ></span>
            </div>
          )}
        </Grid>
      </Grid>

      {themes.length === 0 ? (
        <>
          <Skeleton animation="pulse" width={150} height={30} />
          <Skeleton
            style={{ marginTop: -30, borderRadius: "15px" }}
            animation="wave"
            height={150}
          />
          <Skeleton animation="pulse" width={150} height={30} />
          <Skeleton
            style={{ marginTop: -30, borderRadius: "15px" }}
            animation="wave"
            height={150}
          />
        </>
      ) : (
        themes.map((value, index) => {
          return (
            <div key={index}>
              <div className="spacer-15"></div>
              <p className="info">
                {value.name} {value.price > 0 ? " (PREMIUM TEMPLATE!)" : ""}
              </p>
              <Grid container spacing={2} className="center">
                {value.theme.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={4}>
                        <div
                          className="design"
                          onClick={() => handleClickOpen(`${item.id}`)}
                          style={{ backgroundImage: `url(${item.thumbnail})` }}
                        >
                          <p
                            style={{
                              color: `${item.primary_color}`,
                              textTransform: "uppercase",
                            }}
                          >
                            {item.name}
                          </p>
                          <span
                            className="designcolor"
                            style={{
                              backgroundColor: `${item.button_color}`,
                              boxShadow: `3px 3px 5px ${item.box_shadow_color}`,
                              borderColor: `${item.border_color}`,
                            }}
                          ></span>
                          {value.paid == null &&
                          level == 1 &&
                          value.price != "0" ? (
                            <img src={lock} className="lockdesign" />
                          ) : (
                            ""
                          )}
                          {value.paid == null &&
                          level == 2 &&
                          item.free_for_pro == "0" &&
                          value.price != "0" ? (
                            <img src={lock} className="lockdesign" />
                          ) : (
                            ""
                          )}
                        </div>
                        {item.free_for_pro == 1 ? (
                          <p className="tips for" style={{marginBottom:'-15px'}}>
                            FREE{" "}
                            <font style={{ color: "#FF5B5B" }}>(PRO!)</font>
                          </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
              {value.paid == null && value.price > 0 ? (
                <div className="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="button btn-sm button-primary"
                    onClick={() =>
                      handlePayment(value.id, value.name, value.real_price)
                    }
                    style={{marginTop:'25px'}}
                  >
                    HANYA IDR {value.price}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Preview Design
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSimpanDesign}>
            <SaveIcon />&nbsp;SAVE
            </Button>
          </Toolbar>
        </AppBar>

        <Preview username={username} idPreview={idPreview} />
      </Dialog>
      <Backdrop
        style={{ zIndex: 99999999999 }}
        className={classes.backdrop}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  );
}

export default App;
