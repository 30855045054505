import React, { useEffect } from "react";
import "../App.css";
import logoText from "../img/logo-text.png";
import phone from "../img/phone.png";
import rectangle from "../img/rectangle.png";
import rectangleMobile from "../img/rectangle-mobile.png";
import linkTop from "../img/link-top.png";
import cat1 from "../img/cat1.png";
import cat2 from "../img/cat2.png";
import cat3 from "../img/cat3.png";
import whatsapp from "../img/whatsapp.png";
import shopee from "../img/shopee.png";
import website from "../img/website.png";
import dst from "../img/dst.png";
import baju from "../img/baju.png";
import sepatu from "../img/sepatu.png";
import promo from "../img/promo.png";
import makeup from "../img/makeup.png";
import lifestyle from "../img/lifestyle.png";
import endorsement from "../img/endorsement.png";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "@brainhubeu/react-carousel/lib/style.css";
import InputIcon from "@material-ui/icons/Input";
import AOS from "aos";
import "aos/dist/aos.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

function App() {
  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    document.body.style.fontFamily = "segoeui";
    AOS.init({
      duration: 2000,
    });
    return () => {    
      document.body.style.fontFamily = "Roboto-Regular";
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="home">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item md={4} xs={3}>
              <div className="hblog">
                <a
                  style={{
                    color: "#707070",
                    textDecoration: "none",
                  }}
                  href="#"
                >
                  BLOG
                </a>
              </div>
            </Grid>
            <Grid item md={4} xs={6} className="center">
              <img
                src={logoText}
                alt="logo-text-soosoon"
                className="logohome"
                width="200px"
              />
            </Grid>
            <Grid item md={4} xs={3}>
              <div className="hlogin">
                <a
                  style={{
                    color: "#707070",
                    textDecoration: "none",
                  }}
                  href="/login"
                  target="_blank"
                >
                  LOGIN&nbsp;&nbsp;&nbsp;&nbsp;
                  <InputIcon
                    style={{
                      color: "#707070",
                      marginBottom: "-6px",
                    }}
                    fontSize="medium"
                  />
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="onmob">
            <Grid item xs={6}>
              <div className="hblog bl">
                <a
                  style={{
                    color: "#707070",
                    textDecoration: "none",
                  }}
                  href="#"
                >
                  BLOG
                </a>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="hlogin bl">
                <a
                  style={{
                    color: "#707070",
                    textDecoration: "none",
                  }}
                  href="/login"
                  target="_blank"
                >
                  LOGIN&nbsp;&nbsp;
                  <InputIcon
                    style={{
                      color: "#707070",
                      marginBottom: "-6px",
                    }}
                    fontSize="medium"
                  />
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="spacer-15-mob"></div>
        <Container className="center">
          <p className="des">
            SOOSOON DAN RAPIHKAN INSTAGRAM KAMU <br className="nm" />
            DALAM SEKEJAP!
          </p>
          <a href="/login" target="_blank" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="button btn-1"
              style={{
                color: "#ffffff",
                backgroundColor: "#9DB5BB",
                boxShadow: `none`,
                border: `none`,
                fontWeight: "bold",
                padding: "15px 30px",
                marginTop: "15px",
              }}
            >
              MULAI SEKARANG, GRATIS!
            </Button>
          </a>
        </Container>
        <div className="spacer-60"></div>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item sm={5} md={4}>
              <img
                data-aos-anchor-placement="top-bottom"
                data-aos="fade-up"
                src={phone}
                alt="phone"
                width="100%"
              />
            </Grid>
            <Grid item sm={1} md={1} className="center">
              <img
                src={rectangle}
                alt="rectangle"
                width="60px"
                className="bounce rectangle1 d"
              />
              <img
                src={rectangleMobile}
                alt="rectangle"
                width="60px"
                className="bounce rectangle1 m"
              />
            </Grid>
            <Grid item sm={5} md={4}>
              <div className="centermob">
                <img
                  src={linkTop}
                  alt="link-top"
                  width="90%"
                  style={{ marginTop: "18px" }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-down"
                />
                <img
                  src={cat1}
                  alt="link-top"
                  width="90%"
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="200"
                />
                <img
                  src={cat2}
                  alt="link-top"
                  width="90%"
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="300"
                />
                <img
                  src={cat3}
                  alt="link-top"
                  width="90%"
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="400"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="spacer-60"></div>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6}>
              <div
                className="mid"
                data-aos-anchor-placement="top-bottom"
                data-aos="zoom-in"
              >
                <p>TAMBAHKAN LINK FAVORITE KAMU</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="linklink zz1">
                <img
                  src={whatsapp}
                  style={{ zIndex: 4 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                />
                <img
                  src={shopee}
                  style={{ zIndex: 3 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="300"
                />
                <img
                  src={website}
                  style={{ zIndex: 2 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="600"
                />
                <img
                  src={dst}
                  style={{ zIndex: 1 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="fade-up"
                  data-aos-delay="900"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="spacer-60"></div>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6} className="d">
              <div className="linklink zz2 zoom">
                <img
                  src={baju}
                  style={{ zIndex: 4 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                />
                <img
                  src={sepatu}
                  style={{ zIndex: 3 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                />
                <img
                  src={promo}
                  style={{ zIndex: 2 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="600"
                />
              </div>
            </Grid>
            <Grid item sm={5}>
              <div
                className="mid"
                data-aos-anchor-placement="top-bottom"
                data-aos="zoom-in"
              >
                <p>
                  BERJUALAN LEWAT INSTAGRAM LEBIH MUDAH DENGAN SISTEM KATEGORI
                  MENGGUNAKAN HASHTAG
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="m">
              <div className="linklink zz2 zoom">
                <img
                  src={baju}
                  style={{ zIndex: 4 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                />
                <img
                  src={sepatu}
                  style={{ zIndex: 3 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                />
                <img
                  src={promo}
                  style={{ zIndex: 2 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="600"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="spacer-60"></div>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6}>
              <div
                className="mid"
                data-aos-anchor-placement="top-bottom"
                data-aos="zoom-in"
              >
                <p>COCOK JUGA UNTUK INFLUENCER SEPERTI KAMU!</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div className="linklink zz3 zoom">
                <img
                  src={makeup}
                  style={{ zIndex: 3 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                />
                <img
                  src={lifestyle}
                  style={{ zIndex: 2 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                />
                <img
                  src={endorsement}
                  style={{ zIndex: 1 }}
                  data-aos-anchor-placement="top-bottom"
                  data-aos="zoom-in"
                  data-aos-delay="600"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="spacer-60"></div>
        <Container className="center">
          <a href="/login" target="_blank" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="button btn-1"
              style={{
                color: "#ffffff",
                backgroundColor: "#9DB5BB",
                boxShadow: `none`,
                border: `none`,
                fontWeight: "bold",
                padding: "15px 30px",
                marginTop: "30px",
              }}
            >
              MULAI SEKARANG, GRATIS!
            </Button>
          </a>
        </Container>
        <div className="spacer-60"></div>
        <Container>
          <div
            style={{
              width: "90%",
              height: "1px",
              backgroundColor: "#707070",
              display: "block",
              margin: "0 auto",
              marginBottom: "20px",
            }}
          ></div>
          <Grid container spacing={2} justify="center" className="center">
            <Grid item md={2}>
              <Link
                style={{
                  color: "#707070",
                  textDecoration: "none",
                }}
                to="/terms-and-conditions"
              >
                TERMS AND CONDITIONS
              </Link>
            </Grid>
            <Grid item md={2}>
              <Link
                style={{
                  color: "#707070",
                  textDecoration: "none",
                }}
                to="/privacy-policies"
              >
                PRIVACY POLICY
              </Link>
            </Grid>
            <Grid item md={2}>
              <a
                style={{
                  color: "#707070",
                  textDecoration: "none",
                }}
                href="https://grabessentials.id"
                target="_blank"
              >
                CORPORATE SOLUTION
              </a>
            </Grid>
            <Grid item md={2}>
              <a
                style={{
                  color: "#707070",
                  textDecoration: "none",
                }}
                href="https://grabessentials.id/contact/"
                target="_blank"
              >
                CONTACT US
              </a>
            </Grid>
          </Grid>
          <div className="spacer-30"></div>
        </Container>
      </div>
      {/* <div className="spacer-30"></div>
        <div className="center">
          <img src={logo} alt="logo" />
          <br />
          <img src={logoText} alt="logo-text" />
          <br />
          <br />
          <br />
          <br />
          <Link
            style={{
              color: "#707070",
              textDecoration: "none",
              fontSize: "22px",
              marginTop: "25px",
            }}
            to="/login"
          >
            Login
          </Link>
          <br />
          <br />
          <Link
            style={{
              color: "#707070",
              textDecoration: "none",
              fontSize: "22px",
              marginBottom: "25px",
            }}
            to="/terms-and-conditions"
          >
            Terms and Conditions
          </Link>
          <br />
          <br />
          <Link
            style={{
              color: "#707070",
              textDecoration: "none",
              fontSize: "22px",
              marginTop: "25px",
            }}
            to="/privacy-policies"
          >
            Privacy Policies
          </Link>
        </div> */}
    </MuiThemeProvider>
  );
}

export default App;
