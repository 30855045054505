import React, { useState, useEffect } from "react";
import "../App.css";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Skeleton } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Preview from "../component/Preview";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import introJs from "intro.js";
import "intro.js/introjs.css";
import Fab from "@material-ui/core/Fab";
import InfoIcon from "@material-ui/icons/Info";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ReactDragListView from "react-drag-listview";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#383B67" },
    secondary: { main: "#FF5B5B" },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: "-34px",
    textAlign: "center",
    flex: 1,
  },
}));

function App(props) {
  const classes = useStyles();
  const [preview, setPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const [openGuide, setOpenGuide] = useState(true);
  const [activeLink, setActiveLink] = useState(0);
  const [grid, setGrid] = useState(3);
  const [formLink, setFormLink] = useState(0);
  const [links, setLinks] = useState([]);
  const [categories, setCategories] = useState([]);
  const token = localStorage.getItem("api_token");
  const level = localStorage.getItem("level");
  const [myTheme, setMyTheme] = useState([]);
  const username = localStorage.getItem("username");
  const [activeAccordion, setActiveAccordino] = useState([]);
  // const data111 = [];
  //   for (let i = 1, len = 7; i < len; i++) {
  //     data111.push({
  //       title: `rows${i}`
  //     });
  //   }
  //   const [data, setData] = useState(data111);

  useEffect(() => {
    getLinks();
    getCategories();
    getMyTheme();
  }, []);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data111 = [...categories];
      const item = data111.splice(fromIndex, 1)[0];
      data111.splice(toIndex, 0, item);
      setCategories(data111);
      axios
        .put(
          `https://soosoon.id/backend/public/api/categories_sort`,
          {
            data: data111,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === true) {
            getCategories();
          }
        });
    },
    nodeSelector: ".dragndrop",
    handleSelector: ".dragindicator",
  };

  const startStepsFirst = () => {
    introJs().start();
    setOpenGuide(false);

    axios.put(
      `https://soosoon.id/backend/public/api/users/tutorial`,
      {
        tutorial: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const startSteps = () => {
    introJs().start();
  };

  const getLinks = () => {
    axios
      .get("https://soosoon.id/backend/public/api/links", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setLinks(res.data.data);
        }
      });
  };

  const getCategories = () => {
    axios
      .get("https://soosoon.id/backend/public/api/categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setCategories(res.data.data);
          let arr = [];
          res.data.data.map((value, index) => {
            if (value.sort == 1) {
              arr[index] = 1;
            } else if (value.hashtag && value.name) {
              arr[index] = 1;
            } else {
              arr[index] = 0;
            }
          });
          setActiveAccordino(arr);
        }
      });
  };

  const getCategories1 = () => {
    axios
      .get("https://soosoon.id/backend/public/api/categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setCategories(res.data.data);
        }
      });
  };

  const getMyTheme = () => {
    axios
      .get("https://soosoon.id/backend/public/api/themes/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setMyTheme(res.data.data);
        }
      });
  };

  const changeActiveLink = (newValue) => {
    setActiveLink(newValue);
    setFormLink(newValue);
  };

  const changeActiveAccordion = (index) => {
    let newacc = [...activeAccordion];
    if (newacc[index] == 0) {
      newacc[index] = 1;
    } else {
      newacc[index] = 0;
    }
    setActiveAccordino(newacc);
  };

  const handleClose = () => {
    setPreview(false);
  };

  const handleNameLink = (e, id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/links/name/${id}`,
        {
          name: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getLinks();
      });
  };

  const handleUrlLink = (e, id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/links/url/${id}`,
        {
          url: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getLinks();
      });
  };

  const updateStatusLink = (id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/links/status/${id}`,
        {
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getLinks();
      });
  };

  const addLink = () => {
    setOpen(true);
    axios
      .post(
        "https://soosoon.id/backend/public/api/links",
        {
          name: "",
          url: "",
          sort: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getLinks();
        const jmlh = links.length;
        changeActiveLink(jmlh);
        setOpen(false);
      });
  };

  const deleteLink = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `<p>Hapus Link</p>`,
      text: "Anda yakin ingin menghapus link ini?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        const data = {
          id: id,
          name: "",
          url: "",
        };
        putLink(data);
      }
    });
  };

  const deleteProLink = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `<p>Hapus Link</p>`,
      text: "Anda yakin ingin menghapus link ini?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        setOpen(true);
        axios
          .delete(`https://soosoon.id/backend/public/api/links/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setOpen(false);
            getLinks();
          });
      }
    });
  };

  const putLink = (data) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/links/${data.id}`,
        {
          name: data.name,
          url: data.url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getLinks();
        document.getElementById(`formlink${data.id}`).reset();
      });
  };

  const putCategories = (data) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/categories/${data.id}`,
        {
          name: data.name,
          hashtag: data.hashtag,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getCategories1();
        document.getElementById(`form${data.id}`).reset();
      });
  };

  const updateStatus = (id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/categories/status/${id}`,
        {
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getCategories1();
      });
  };

  const deleteProCategory = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `<p>Hapus Kategori</p>`,
      text: "Anda yakin ingin menghapus kategori ini?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        setOpen(true);
        axios
          .delete(`https://soosoon.id/backend/public/api/categories/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setOpen(false);
            getCategories1();
          });
      }
    });
  };

  const deleteCategory = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `<p>Hapus Kategori</p>`,
      text: "Anda yakin ingin menghapus kategori ini?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      customClass: {
        container: "container-class",
        popup: "popup-class",
        title: "title-class",
        content: "content-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
    }).then((res) => {
      if (res.value === true) {
        const data = {
          id: id,
          name: "",
          hashtag: "",
        };
        putCategories(data);
      }
    });
  };

  const handleNameCategories = (e, id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/categories/name/${id}`,
        {
          name: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getCategories1();
      });
  };

  const handleCategories = (e, i, target) => {
    let newcat = [...categories];
    if (target == "name") {
      newcat[i].name = e.target.value;
    } else if (target == "hashtag") {
      newcat[i].hashtag = e.target.value;
    }
    setCategories(newcat);
  };

  const handleHashtagCategories = (e, id) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/categories/hashtag/${id}`,
        {
          hashtag: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getCategories1();
      });
  };

  const addCategory = () => {
    setOpen(true);
    axios
      .post(
        "https://soosoon.id/backend/public/api/categories",
        {
          name: "",
          hashtag: "",
          type: "",
          sort: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getCategories1();
        let newacc = [...activeAccordion];
        const jmlh = newacc.length;
        newacc[jmlh] = 1;
        setActiveAccordino(newacc);
        setOpen(false);
      });
  };

  const looping = () => {
    let content = [];
    let children = [];
    let form = [];
    let start = 0;
    let end = 2;
    const countlink = links.length;
    const jmlhloop = Math.ceil(countlink / 3);
    for (let x = 0; x < jmlhloop; x++) {
      links.map((value, index) => {
        let i = index + 1;
        if (index >= start && index <= end) {
          children.push(
            <>
              <Grid item xs={4} style={{ position: "relative" }}>
                <Button
                  variant="contained"
                  color={value.name && value.url ? "secondary" : "primary"}
                  size="small"
                  fullWidth
                  className={
                    value.name && value.url
                      ? "button button-secondary btn-sm"
                      : "button btn-sm"
                  }
                  onClick={() => changeActiveLink(index)}
                >
                  {value.name && value.url
                    ? value.name.length < 10
                      ? value.name
                      : value.name.substring(0, 9) + "..."
                    : `Buat Link ${i}`}
                </Button>
                {activeLink === index && (
                  <>
                    <div
                      className={
                        index == start ? "triangle triangle1" : "triangle"
                      }
                    ></div>
                    <div className="triangle2"></div>
                  </>
                )}
              </Grid>
            </>
          );

          form.push(
            formLink == index && (
              <>
                <form className="form form1" id={`formlink${value.id}`}>
                  <input
                    className={value.name ? "input1 inputsuccess" : "input1"}
                    placeholder="Nama Link (Max. 12 karakter)"
                    defaultValue={value.name ? value.name : ""}
                    onBlur={(e) => handleNameLink(e, value.id)}
                    data-intro="Isi nama link kamu disini"
                  />
                  <input
                    className={value.url ? "input1 inputsuccess" : "input1"}
                    placeholder="https://URL (LINK)"
                    defaultValue={value.url ? value.url : ""}
                    // onInput={(e) =>
                    //   (e.target.value = e.target.value.toLowerCase())
                    // }
                    onBlur={(e) => handleUrlLink(e, value.id)}
                    data-intro="Isi url link kamu disini"
                  />
                  {value.sort == 7 ? (
                    <div style={{ textAlign: "right" }}>
                      {value.name && value.url && (
                        <Switch
                          size="small"
                          color="primary"
                          checked={value.status == "1" ? "true" : ""}
                          onChange={() => updateStatusLink(value.id)}
                        />
                      )}
                      <DeleteForeverOutlinedIcon
                        onClick={() => deleteProLink(value.id)}
                        color="primary"
                        style={{ marginBottom: "-7px" }}
                      />
                    </div>
                  ) : (
                    value.name &&
                    value.url && (
                      <div style={{ textAlign: "right" }}>
                        <Switch
                          size="small"
                          color="primary"
                          checked={value.status == "1" ? "true" : ""}
                          onChange={() => updateStatusLink(value.id)}
                        />
                        <DeleteForeverOutlinedIcon
                          onClick={() => deleteLink(value.id)}
                          color="primary"
                          style={{ marginBottom: "-7px" }}
                        />
                      </div>
                    )
                  )}
                </form>
              </>
            )
          );
        }
      });

      content.push(
        <>
          {x == 0 ? (
            <Grid
              container
              spacing={3}
              className="center"
              data-intro="Kamu dapat membuat link yang kamu inginkan dengan klik button ini"
            >
              {children}
            </Grid>
          ) : (
            <Grid container spacing={3} className="center">
              {children}
            </Grid>
          )}
          <div className="spacer-15"></div>
          {form}
          <div className="spacer-15"></div>
        </>
      );
      children = [];
      form = [];
      start = start + 3;
      end = end + 3;
    }
    return content;
  };

  const updateGrid = (e) => {
    axios
      .put(
        `https://soosoon.id/backend/public/api/users/grid_link`,
        {
          grid_link: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => props.onGridChange(res.data.grid_link));
  };

  return (
    <MuiThemeProvider theme={theme}>
      {links.length == 0 ? (
        <>
          <Grid container spacing={3} className="center">
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </Grid>
          </Grid>
          <div className="spacer-30"></div>
        </>
      ) : (
        looping()
      )}
      {level == 1 && (
        <Grid container spacing={3} className="center">
          <Grid item xs={4} style={{ position: "relative" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className="button btn-sm btn-disabled"
              onClick={() => props.onCounterChange(2)}
            >
              Link<span className="pro">&nbsp;(PRO)</span>
            </Button>
          </Grid>
          <Grid item xs={4} style={{ position: "relative" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className="button btn-sm btn-disabled"
              onClick={() => props.onCounterChange(2)}
            >
              Link<span className="pro">&nbsp;(PRO)</span>
            </Button>
          </Grid>
          <Grid item xs={4} style={{ position: "relative" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className="button btn-sm btn-disabled"
              onClick={() => props.onCounterChange(2)}
            >
              Link<span className="pro">&nbsp;(PRO)</span>
            </Button>
          </Grid>
        </Grid>
      )}
      {level == 2 && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="linkkbtn btnkat"
          onClick={() => addLink()}
        >
          TAMBAH LINK
        </Button>
      )}
      <div className="spacer-30"></div>
      <Grid
        container
        spacing={1}
        className="center"
        data-intro="Kamu dapat mengatur jumlah kolom yang tampil disini"
      >
        <Grid item xs={5}>
          <p>PILIHAN KOLOM :</p>
        </Grid>
        <Grid item xs={7}>
          <FormControl>
            <NativeSelect
              value={props.gridLink}
              name="age"
              inputProps={{ "aria-label": "kolom" }}
              onChange={(e) => updateGrid(e)}
            >
              <option value="1">1 kolom</option>
              <option value="2">2 kolom</option>
              <option value="3">3 kolom</option>
            </NativeSelect>
            <FormHelperText>Pilih kolom yang kamu inginkan!</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <div className="spacer-15"></div>
      <h2 className="h-line">
        <span>KATEGORI</span>
      </h2>
      <ReactDragListView {...dragProps}>
        <div data-intro="Kelola kategori kamu disini" data-scrollTo="tooltip">
          {categories.length == 0 ? (
            <>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
              <div className="spacer-15"></div>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
              <div className="spacer-15"></div>
              <Skeleton
                style={{ borderRadius: "15px" }}
                animation="wave"
                height={60}
              />
            </>
          ) : (
            categories.map((value, index) => {
              let i = index + 1;
              if (i < 10) {
                i = "0" + i;
              }
              return (
                <div className="dragndrop">
                  <Button
                    variant="contained"
                    color={
                      value.name && value.hashtag ? "secondary" : "primary"
                    }
                    fullWidth
                    className={
                      value.name && value.hashtag
                        ? "button-secondary linkkbtn btnkat jcl"
                        : "linkkbtn btnkat"
                    }
                    onClick={() => changeActiveAccordion(index)}
                  >
                    {value.name && value.hashtag ? (
                      <>
                        <DragIndicatorIcon
                          size="small"
                          className="dragindicator"
                          draggable
                          style={{
                            marginLeft: "-15px",
                            marginRight: "5px",
                          }}
                        />
                        {value.name}
                        {/* <span className="hash">&nbsp;({value.hashtag})</span> */}
                        {value.type == 1 ? (
                          <span
                            className="typekategori"
                            data-intro={
                              value.sort == 2
                                ? "Kategori selanjutnya akan tampil dengan layout grid 3 kolom"
                                : null
                            }
                          >
                            GRID
                          </span>
                        ) : (
                          <span
                            className="typekategori"
                            data-intro="Kategori pertama akan tampil dengan layout slider"
                          >
                            SLIDER
                          </span>
                        )}
                      </>
                    ) : value.type == 1 ? (
                      `BUAT KATEGORI ${i} (GRID)`
                    ) : (
                      `BUAT KATEGORI ${i} (TOP SLIDER)`
                    )}
                  </Button>
                  <div
                    className={
                      activeAccordion[index] == 1
                        ? "form form1 form2"
                        : "form form1 form2 accordion-non"
                    }
                  >
                    <form id={`form${value.id}`}>
                      {value.sort == 1 ? (
                        <>
                          <input
                            className={
                              value.name ? "input1 inputsuccess" : "input1"
                            }
                            placeholder="Nama Kategori (Max. 12 karakter)"
                            value={value.name ? value.name : ""}
                            onChange={(e) => handleCategories(e, index, "name")}
                            onBlur={(e) => handleNameCategories(e, value.id)}
                            data-intro="Isi nama kategori yang kamu inginkan disini"
                          />
                          <input
                            className={
                              value.hashtag ? "input1 inputsuccess" : "input1"
                            }
                            placeholder="Hashtag Unik Kategori"
                            style={{ marginBottom: 0 }}
                            value={value.hashtag ? value.hashtag : ""}
                            onChange={(e) =>
                              handleCategories(e, index, "hashtag")
                            }
                            onInput={(e) => {
                              if (
                                !e.target.value.match("#") &&
                                e.target.value != ""
                              ) {
                                e.target.value = "#" + e.target.value;
                              }
                            }}
                            onBlur={(e) => handleHashtagCategories(e, value.id)}
                            data-intro="Silakan buat hashtag unik untuk kategori ini, kemudian masukkan # tersebut pada post IG kamu"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            className={
                              value.name ? "input1 inputsuccess" : "input1"
                            }
                            placeholder="Nama Kategori (Max. 12 karakter)"
                            value={value.name ? value.name : ""}
                            onChange={(e) => handleCategories(e, index, "name")}
                            onBlur={(e) => handleNameCategories(e, value.id)}
                          />
                          <input
                            className={
                              value.hashtag ? "input1 inputsuccess" : "input1"
                            }
                            placeholder="Hashtag Unik Kategori"
                            style={{ marginBottom: 0 }}
                            value={value.hashtag ? value.hashtag : ""}
                            onChange={(e) =>
                              handleCategories(e, index, "hashtag")
                            }
                            onInput={(e) => {
                              if (
                                !e.target.value.match("#") &&
                                e.target.value != ""
                              ) {
                                e.target.value = "#" + e.target.value;
                              }
                            }}
                            onBlur={(e) => handleHashtagCategories(e, value.id)}
                          />
                        </>
                      )}
                    </form>
                    <p className="tips" style={{ marginLeft: 0 }}>
                      Tambahkan hashtag pada post Anda
                    </p>
                    {value.sort == 4 ? (
                      <div style={{ textAlign: "right" }}>
                        {value.name && value.hashtag && (
                          <>
                            <Switch
                              size="small"
                              color="primary"
                              checked={value.status == "1" ? "true" : ""}
                              onChange={() => updateStatus(value.id)}
                            />
                          </>
                        )}
                        <DeleteForeverOutlinedIcon
                          onClick={() => deleteProCategory(value.id)}
                          color="primary"
                          style={{ marginBottom: "-7px" }}
                        />
                      </div>
                    ) : (
                      value.name &&
                      value.hashtag && (
                        <div style={{ textAlign: "right" }}>
                          <Switch
                            size="small"
                            color="primary"
                            checked={value.status == "1" ? "true" : ""}
                            onChange={() => updateStatus(value.id)}
                          />
                          <DeleteForeverOutlinedIcon
                            onClick={() => deleteCategory(value.id)}
                            color="primary"
                            style={{ marginBottom: "-7px" }}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <div className="spacer-15"></div>
                </div>
              );
            })
          )}
        </div>
      </ReactDragListView>
      <div className="spacer-15"></div>
      {level == 1 && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="linkkbtn btnkat btn-disabled"
          onClick={() => props.onCounterChange(2)}
        >
          TAMBAH KATEGORI UNLIMITED&nbsp;<span className="pro">PRO!</span>
        </Button>
      )}
      {level == 2 && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="linkkbtn btnkat"
          onClick={() => addCategory()}
        >
          TAMBAH KATEGORI
        </Button>
      )}
      <div className="spacer-30"></div>
      <div className="center">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className="button btn-sm button-secondary btn-rounded"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          onClick={() => setPreview(true)}
          disabled={myTheme.length === 0 ? "true" : ""}
          data-intro="Klik tombol ini untuk melihat preview tampilan soosoon milikmu"
        >
          PREVIEW
        </Button>
      </div>
      <div className="spacer-15"></div>
      <h2 className="h-line">
        <span>DESIGN YANG DIGUNAKAN</span>
      </h2>
      <div className="spacer-15"></div>
      <Grid
        container
        spacing={2}
        className="center"
        data-intro="Ini template desain yang sedang kamu gunakan, klik ubah design untuk pindah ke tab design"
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          {myTheme.length === 0 ? (
            <Skeleton
              style={{ marginTop: -30, borderRadius: "15px" }}
              animation="wave"
              height={150}
            />
          ) : (
            <div
              className="design"
              style={{ backgroundImage: `url(${myTheme.thumbnail})` }}
            >
              <p
                style={{
                  color: `${myTheme.primary_color}`,
                  textTransform: "uppercase",
                }}
              >
                {myTheme.name}
              </p>
              <span
                className="designcolor"
                style={{
                  backgroundColor: `${myTheme.button_color}`,
                  boxShadow: `3px 3px 5px ${myTheme.box_shadow_color}`,
                  borderColor: `${myTheme.border_color}`,
                }}
              ></span>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <div style={{ textAlign: "left" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="button btn-sm button-primary"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                marginTop: "32px",
              }}
              onClick={() => props.onCounterChange(1)}
            >
              UBAH DESIGN
            </Button>
          </div>
        </Grid>
      </Grid>

      <div style={{ position: "fixed", bottom: 10, right: 10 }}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => startSteps()}
        >
          <InfoIcon />
        </Fab>
      </div>

      <Dialog
        fullScreen
        open={preview}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Preview
            </Typography>
          </Toolbar>
        </AppBar>

        <Preview username={username} idPreview={myTheme.id} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.tutorial == 0 && (
        <Backdrop className={classes.backdrop} open={openGuide}>
          <div style={{ position: "fixed", bottom: 13, right: 70 }}>
            <Button
              variant="contained"
              color="primary"
              className="button btn-sm button-primary"
            >
              Lihat Tutorial <ArrowRightAltIcon />
            </Button>
          </div>
          <div style={{ position: "fixed", bottom: 10, right: 10 }}>
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={() => startStepsFirst()}
            >
              <InfoIcon />
            </Fab>
          </div>
        </Backdrop>
      )}
    </MuiThemeProvider>
  );
}

export default App;
